import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { device } from '../../../utils/media-queries'
// @ts-ignore
import { confirmAlert } from 'react-confirm-alert' // Import
import styled from 'styled-components'
import BaseLayout from 'components/layouts/base_layout'
import RegularButton from 'components/buttons/regular_button'
import { Switch } from 'components/buttons/switch'

import {
  FightForm,
  FightFormFieldLabel,
  FightFormFieldInput,
  FightFormFieldSelect,
  FightFormField,
  FightFormRowHalf,
  FightFormDateInput,
} from 'components/forms'
import { DateDisplay } from 'components/date_display'

export default function EditScorecard(props: any) {
  let { scorecard, csrf_param, csrf_token, current_user } = props
  let seriesUI
  let weightUI
  let switchCheck

  const [boxerAValue, setBoxerAValue] = useState(scorecard.boxerA)
  const [boxerBValue, setBoxerBValue] = useState(scorecard.boxerB)
  const [roundsValue, setRoundsValue] = useState(scorecard.rounds)
  const [weightClassValue, setWeightClassValue] = useState(
    scorecard.weightClass,
  )
  var fightDate = new Date(scorecard.nsDate)
  fightDate.setDate(fightDate.getDate() + 1)
  const [dateValue, setDateValue] = useState(fightDate)
  const [recommendationValue, setRecommendationValue] = useState(
    scorecard.recommendation_id,
  )
  const [seriesValue, setSeriesValue] = useState(scorecard.series)

  if (seriesValue == 0 || seriesValue == 1) {
    switchCheck = false
  } else {
    switchCheck = true
  }

  const [isRematchChecked, setRematchCheck] = useState(switchCheck)

  function handleDelete(e: any) {
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      method: 'DELETE',
      url: `/scorecards/${scorecard.id}`,
      success: function () {
        console.log('Scorecard deleted')
      }.bind(this),
      error: function (status, error) {
        alert(`Cannot delete requested record: ${error}, status: ${status}`)
      },
    })
  }

  function deleteSubmit(e: any) {
    e.preventDefault()
    confirmAlert({
      // eslint-disable-next-line react/display-name
      customUI: ({ onClose }) => {
        return (
          <ModalContainer>
            <ModalTitle>Delete scorecard?</ModalTitle>
            <ModalDescription>
              Are you sure you wish to delete {scorecard.boxerA} vs{' '}
              {scorecard.boxerB}?
            </ModalDescription>

            <ModalActions>
              <RegularButton buttonTitle='Cancel' onClick={onClose} path='' />
              <RegularButton
                buttonTitle='Delete'
                colour='#bca573'
                onClick={() => {
                  handleDelete(e)
                  onClose()
                }}
              />
            </ModalActions>
          </ModalContainer>
        )
      },
    })
  }

  function handleUpdate(e: any) {
    const updatedScorecard = {
      ...scorecard,
      boxerA: boxerAValue,
      boxerB: boxerBValue,
      rounds: roundsValue,
      recommendation_id: recommendationValue,
      nsDate: dateValue,
      weightClass: weightClassValue,
      series: seriesValue,
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: `/scorecards/${scorecard.id}`,
      type: 'PATCH',
      data: { fight: updatedScorecard },
      success: () => {
        console.log('Scorecard updated')
      },
    })
  }

  weightUI = (
    <>
      <FightFormFieldLabel htmlFor='weight'>Weight Class</FightFormFieldLabel>
      <FightFormFieldSelect
        id='weight'
        name='weight'
        value={weightClassValue}
        onChange={(e) => setWeightClassValue(e.target.value)}>
        <option id='weight' value=''>
          None set
        </option>
        <option id='weight' value='Heavy'>
          Heavyweight
        </option>
        <option id='weight' value='Cruiser'>
          Cruiserweight
        </option>
        <option id='weight' value='Light Heavy'>
          Light Heavyweight
        </option>
        <option id='weight' value='Super Middle'>
          Super Middleweight
        </option>
        <option id='weight' value='Middle'>
          Middleweight
        </option>
        <option id='weight' value='Super Welter'>
          Light Middleweight
        </option>
        <option id='weight' value='Welter'>
          Welterweight
        </option>
        <option id='weight' value='Super Light'>
          Super Lightweight
        </option>
        <option id='weight' value='Light'>
          Lightweight
        </option>
        <option id='weight' value='Super Feather'>
          Super Featherweight
        </option>
        <option id='weight' value='Feather'>
          Featherweight
        </option>
        <option id='weight' value='Super Bantam'>
          Super Bantamweight
        </option>
        <option id='weight' value='Bantam'>
          Bantamweight
        </option>
        <option id='weight' value='Super Fly'>
          Super Flyweight
        </option>
        <option id='weight' value='Fly'>
          Flyweight
        </option>
        <option id='weight' value='Light Fly'>
          Light Flyweight
        </option>
        <option id='weight' value='Minimum'>
          Minimumweight
        </option>
      </FightFormFieldSelect>
    </>
  )

  if (isRematchChecked) {
    seriesUI = (
      <FightFormFieldSelect
        id='series'
        name='series'
        value={seriesValue}
        onChange={(e) => setSeriesValue(e.target.value)}>
        <option id='series' value={1}>
          0
        </option>
        <option id='series' value={2}>
          2 - Rematch
        </option>
        <option id='series' value={3}>
          3 - Trilogy
        </option>
        <option id='series' value={4}>
          4 - Quadrilogy
        </option>
      </FightFormFieldSelect>
    )
  } else {
    seriesUI = ''
  }

  return (
    <>
      <BaseLayout
        title='Scorecards'
        backButton={true}
        buttonTitle='All Scorecards'
        path='/scorecards/'
        current_user={current_user}>
        <Container>
          <FightIntro>
            <FightTitle>
              <FightDate>
                <DateDisplay fight={scorecard} date={scorecard.nsDate} />
              </FightDate>
              <Title>
                {scorecard.boxerA} vs {scorecard.boxerB}
              </Title>
              <TitleUnderline />
            </FightTitle>
          </FightIntro>

          <FightDetails>
            <FightForm
              className='edit_fight'
              id={`edit_fight_${scorecard.id}`}
              action={`/fights/${scorecard.id}`}
              acceptCharset='UTF-8'
              method='post'>
              <input name='utf8' type='hidden' value='✓' />
              <input type='hidden' name='_method' value='patch' />
              <input type='hidden' name={csrf_param} value={csrf_token} />

              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerA'>
                    Boxer A name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerA'
                    id='boxerA'
                    type='text'
                    value={boxerAValue}
                    onChange={(e) => setBoxerAValue(e.target.value)}
                  />
                </FightFormField>

                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerB'>
                    Boxer B name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerB'
                    id='boxerB'
                    type='text'
                    value={boxerBValue}
                    onChange={(e) => setBoxerBValue(e.target.value)}
                  />
                </FightFormField>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='rounds'>
                    Scheduled Rounds
                  </FightFormFieldLabel>
                  <FightFormFieldSelect
                    id='rounds'
                    name='rounds'
                    value={roundsValue}
                    onChange={(e) => setRoundsValue(e.target.value)}>
                    <option id='rounds' value={3}>
                      3
                    </option>
                    <option id='rounds' value={4}>
                      4
                    </option>
                    <option id='rounds' value={5}>
                      5
                    </option>
                    <option id='rounds' value={6}>
                      6
                    </option>
                    <option id='rounds' value={8}>
                      8
                    </option>
                    <option id='rounds' value={10}>
                      10
                    </option>
                    <option id='rounds' value={12}>
                      12
                    </option>
                    <option id='rounds' value={15}>
                      15
                    </option>
                  </FightFormFieldSelect>
                </FightFormField>

                <FightFormField>
                  <FightFormFieldLabel>Fight date</FightFormFieldLabel>
                  <FightFormDateInput>
                    <DatePicker
                      dateFormat='yyyy-MM-dd'
                      selected={dateValue}
                      onChange={(date) => setDateValue(date)}
                    />
                  </FightFormDateInput>
                </FightFormField>
              </FightFormRowHalf>
              <FightFormRowHalf>
                <FightFormField>{weightUI}</FightFormField>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='recommendation_id'>
                    Recommendation ID
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='recommendation_id'
                    id='recommendation_id'
                    type='text'
                    value={recommendationValue}
                    onChange={(e) => setRecommendationValue(e.target.value)}
                  />
                </FightFormField>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='recommendation_id'>
                    Rematch
                  </FightFormFieldLabel>

                  <RematchContainer>
                    <Switch
                      checked={isRematchChecked}
                      onChange={(e) => setRematchCheck(e.target.checked)}
                    />
                    {seriesUI}
                  </RematchContainer>
                </FightFormField>
              </FightFormRowHalf>

              <Actions>
                <RegularButton
                  onClick={(e) => deleteSubmit(e)}
                  buttonTitle='Delete'
                  colour='#17181d'
                />
                <RegularButton
                  path={'/scorecards/' + scorecard.id}
                  buttonTitle='Cancel'
                />
                <RegularButton
                  buttonTitle='Update scorecard'
                  onClick={(event) => handleUpdate(event)}
                  colour='#bca573'
                />
              </Actions>
            </FightForm>
          </FightDetails>
        </Container>
      </BaseLayout>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;

  @media ${device.mobileL} {
    row-gap: 0rem;
    grid-template-rows: 7rem 1fr;
  }
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-contents: space-between;
  align-items: center;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 5rem;
  }
`

const FightTitle = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr 0.5rem;
`

const FightDate = styled.div`
  color: #989898;
  font-size: 0.875rem;
`

const Title = styled.h1`
  color: white;
  font-size: 34px;
  margin: 0.75rem 0;

  @media ${device.mobileL} {
    font-size: 24px;
  }
`

const TitleUnderline = styled.div`
  height: 2px;
  width: 8rem;
  background: #bca573;
`

const FightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem 1fr;
  row-gap: 2rem;
`

const Actions = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 7rem 10rem 12rem;
  justify-content: end;
  column-gap: 1rem;

  @media ${device.mobileL} {
    padding-bottom: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: 3.25rem 3.25rem 3.25rem;
  }
`

const RematchContainer = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
`
// Modal
const ModalContainer = styled.div`
  background: #2a2b2f;
  color: #fff;
  border: 1px solid rgba(152, 152, 152, 0.2);
  padding: 0rem 2rem;
  display: grid;
  grid-template-rows: 4rem 4rem 4rem;
  border-radius: 6px;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.6);
`

const ModalTitle = styled.h2`
  font-size: 32px;
`

const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
`

const ModalActions = styled.div`
  display: grid;
  grid-template-columns: 10rem 10rem;
  justify-content: end;
  column-gap: 2rem;
`
