import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/media-queries'

export function Switch(props: any) {
  const { checked, onChange, label, subLabel } = props

  return (
    <SwitchUI>
      <SwitchLabel>
        <input
          type='checkbox'
          id='myCheck'
          defaultChecked={checked}
          onChange={onChange}
        />
        <span className='slider2 round2'></span>
      </SwitchLabel>

      <TextContent htmlFor='myCheck'>
        <Label htmlFor='myCheck'>{label}</Label>
        <SubLabel htmlFor='myCheck'>{subLabel}</SubLabel>
      </TextContent>
    </SwitchUI>
  )
}

export function MobileSwitch(props: any) {
  const { checked, onChange, label } = props

  return (
    <MobileSwitchUI>
      <SwitchLabel>
        <input
          type='checkbox'
          id='myCheck'
          defaultChecked={checked}
          onChange={onChange}
        />
        <span className='slider2 round2'></span>
      </SwitchLabel>

      <TextContent htmlFor='myCheck'>
        <Label htmlFor='myCheck'>{label}</Label>
      </TextContent>
    </MobileSwitchUI>
  )
}

const SwitchUI = styled.div`
  display: grid;
  grid-template-columns: 4rem 1fr;
`

const MobileSwitchUI = styled.div`
  display: none;
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;

    label {
      text-align: right;
    }
  }
`

const TextContent = styled.label`
  display: grid;
  grid-template-columns: 1fr;
  &:hover {
    cursor: pointer;
  }
`

const Label = styled.label`
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
`

const SubLabel = styled.p`
  color: #989898;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 0.2rem;
`

const SwitchLabel = styled.label`
  position: relative;
  width: 40px;
  height: 24px;
  float: left;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider2 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2a2b2f;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider2:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider2 {
    background-color: #bca573;
  }
  
  input:focus + .slider2 {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider2:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider2.round2 {
    border-radius: 34px;
  }
  
  .slider2.round2:before {
    border-radius: 50%;
  }

}
`
