import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/media-queries'

// @ts-ignore
import backImage from '../../../assets/images/glyph_back.svg'

export default function IconButton(props: any) {
  const { path, icon, buttonTitle } = props

  return (
    <Button href={path}>
      <Icon src={icon} />
      <span>{buttonTitle}</span>
    </Button>
  )
}

IconButton.defaultProps = {
  path: '/',
  icon: { backImage },
  buttonTitle: 'Potato',
}

const Button = styled.a`
  padding: 0.2rem 1rem;
  background: #2a2b2f;
  border-radius: 6px;
  color: white;
  height: 32px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;

  &:hover {
    cursor: pointer;
  }

  @media ${device.mobileL} {
    max-width: 9rem;
    background: transparent;
  }
`

const Icon = styled.img`
  padding-right: 1rem;

  @media ${device.mobileL} {
    padding-right: 0.5rem;
  }
`
