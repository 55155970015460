import React from 'react'
import styled from 'styled-components'

export default function RegularButton(props: any) {
  const { path, buttonTitle, colour, textColour, onClick } = props

  const Button = styled.a`
    padding: 0.2rem 1rem;
    background: ${colour};
    border-radius: 6px;
    color: ${textColour};
    height: 32px;
    display: grid;
    align-items: center;
    grid-template-columns: auto;
    text-align: center;
    transition: 0.3s all ease-in-out;

    &:hover {
      cursor: pointer;
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.19);
      transform: scale(1.02, 1.02);
      color: #bca573;
    }
  `

  return (
    <Button href={path} onClick={onClick}>
      <span>{buttonTitle}</span>
    </Button>
  )
}

RegularButton.defaultProps = {
  path: '/',
  buttonTitle: 'Potato',
  colour: '#2a2b2f',
  textColour: '#fff',
}
