import React from 'react'
import { ListLayout } from 'components/layouts/base_layout/list_layout'
import moment from 'moment'

export default function SchedulePage(props: any) {
  const {
    scheduled,
    showcase1,
    showcase2,
    showcase1_boxerA_avatar,
    showcase1_boxerB_avatar,
    showcase2_boxerA_avatar,
    showcase2_boxerB_avatar,
    current_user,
    csrf_param,
    csrf_token,
  } = props
  var currentMonth = moment(new Date()).format('MMMM')

  return (
    <ListLayout
      title='Scheduled fights'
      fightSearch={true}
      fights={scheduled}
      showcaseTitle={`Major fights this ${currentMonth}`}
      showcase1={showcase1}
      showcase1_boxerA_avatar={showcase1_boxerA_avatar}
      showcase1_boxerB_avatar={showcase1_boxerB_avatar}
      showcase2={showcase2}
      showcase2_boxerA_avatar={showcase2_boxerA_avatar}
      showcase2_boxerB_avatar={showcase2_boxerB_avatar}
      listTitle='Full schedule'
      csrf_param={csrf_param}
      csrf_token={csrf_token}
      current_user={current_user}></ListLayout>
  )
}
