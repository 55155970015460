import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../../utils/media-queries'

import BaseLayout from 'components/layouts/base_layout'
import ScorecardTable from 'components/scorecardTable'
import RegularButton from 'components/buttons/regular_button'
import { Switch, MobileSwitch } from 'components/buttons/switch'
import { DateDisplay } from 'components/date_display'

export default function Scorecard(props: any) {
  let { scorecard, fight, current_user } = props
  const [checked, setChecked] = useState(false)
  let resultUI
  let switchUI
  let mobileSwitchUI
  let scorecardUI

  const onChange = (e) => {
    setChecked(e.target.checked)
  }

  if (scorecard.postWinner == 'nil') {
    resultUI = 'To be decided.'
  } else {
    resultUI = (
      <span>
        {scorecard.postWinner} - {scorecard.postResult}{' '}
        {scorecard.postResultRnd}{' '}
      </span>
    )
  }

  if (fight != null && fight.fight_count > 1) {
    switchUI = (
      <Switch
        checked={checked}
        onChange={onChange}
        label='Show avg scores'
        subLabel={fight.fight_count + ' scorecards'}
      />
    )
    mobileSwitchUI = (
      <MobileSwitch
        checked={checked}
        onChange={onChange}
        label={'Show avg scores (' + fight.fight_count + ')'}
      />
    )
  }

  if (checked) {
    scorecardUI = (
      <AvgScorecardWrapper>
        <ScorecardTable style={{ borderColor: 'red' }} scorecard={fight} />
      </AvgScorecardWrapper>
    )
  } else {
    scorecardUI = <ScorecardTable scorecard={scorecard} />
  }

  return (
    <Fragment>
      <BaseLayout
        title='Scorecards'
        backButton={true}
        buttonTitle='All Scorecards'
        path='/scorecards/'
        current_user={current_user}>
        <Container>
          <FightIntro>
            <FightTitle>
              <FightDate>
                <DateDisplay fight={scorecard} date={scorecard.nsDate} /> -{' '}
                {scorecard.weightClass} {mobileSwitchUI}
              </FightDate>
              <Title>
                {scorecard.boxerA} vs {scorecard.boxerB}
              </Title>
              <TitleUnderline />
            </FightTitle>
            <div className='avgScores'>{switchUI}</div>
          </FightIntro>

          <FightDetails>
            <ScorecardContainer>{scorecardUI}</ScorecardContainer>
            <OfficialScore>
              <label>Official result</label>
              <p>{resultUI}</p>
            </OfficialScore>
            <FightNotes>
              <label>Notes</label>
              <p>{scorecard.notes}</p>
            </FightNotes>
            <Actions>
              <RegularButton
                path={'/scorecards/' + scorecard.id + '/edit/'}
                buttonTitle='Edit'
              />
              <RegularButton
                path={'/scorecards/' + scorecard.id + '/scores/'}
                buttonTitle='Score this fight'
                colour='rgba(187,164,114, 0.3)'
              />
            </Actions>
          </FightDetails>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;

  @media ${device.mobileL} {
    row-gap: 0rem;
  }
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-contents: space-between;
  align-items: center;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    padding-bottom: 2rem;

    .avgScores {
      display: none;
    }
  }
`

const FightTitle = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr 0.5rem;

  @media ${device.mobileL} {
    grid-template-rows: 2rem 1fr 0.5rem;
    row-gap: 1rem;
  }
`

const FightDate = styled.div`
  color: #989898;
  font-size: 0.875rem;

  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: 8rem 12.5rem;
    justify-content: space-between;
    align-items: center;
  }
`

const Title = styled.h1`
  color: white;
  font-size: 34px;
  margin: 0.75rem 0;

  @media ${device.mobileL} {
    font-size: 24px;
  }
`

const TitleUnderline = styled.div`
  height: 2px;
  width: 8rem;
  background: #bca573;
`

const FightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem 1fr;
  row-gap: 2rem;
`

const ScorecardContainer = styled.div`
  @media ${device.mobileL} {
    max-width: 100%;
    overflow-x: scroll;
  }
`

const OfficialScore = styled.div`
  color: #fff;

  label {
    color: #989898;
    font-size: 0.875rem;
  }

  p {
    margin-top: 0.5rem;
  }
`
const FightNotes = styled.div`
  color: #fff;
  line-height: 1.4;

  label {
    color: #989898;
    font-size: 0.875rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: 7rem 10rem;
  justify-contents: start;
  column-gap: 1rem;

  @media ${device.mobileL} {
    padding-bottom: 2rem;
  }
`

const AvgScorecardWrapper = styled.div`
  section {
    border-color: rgba(188, 165, 115, 0.5);
    transition: 0.3s border-color ease-in-out;
  }
`
