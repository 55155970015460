import React from 'react'
import styled from 'styled-components'

import Layout from 'components/layouts/base_layout'

export default function Boxers(props: any) {
  let { boxers, current_user } = props

  const BoxerList = ({ boxers }) => (
    <List>
      {boxers.map((boxer: any, index) => (
        <div key={index}>
          <a href={`/boxers/${boxer.slug}`}>
            <BoxerCard>
              <BoxerImage src={boxer.avatar_url} alt={boxer.firstname} />
              <BoxerName>
                {boxer.firstname} {boxer.surname}
                <br />
                <span>{boxer.nationality}</span>
              </BoxerName>
            </BoxerCard>
          </a>
        </div>
      ))}
    </List>
  )

  return (
    <>
      <Layout title='Boxers' current_user={current_user}>
        <BoxerList boxers={boxers} />
      </Layout>
    </>
  )
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 3%;
  row-gap: 2rem;
`

const BoxerCard = styled.div`
  background: #141419;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  margin: 0;
  padding: 0.5rem 1rem;
  display: grid;
  grid-template-columns: 3.125rem 1fr;
  column-gap: 1rem;
  align-items: center;
  row-gap: 2rem;
  color: #fff;
  line-height: 1.6;

  span {
    color: rgba(152, 152, 152, 1);
  }

  transition: 0.3s all ease-in-out;

  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.19);
    transform: scale(1.02, 1.02);

    figure {
      color: #bca573;
    }
  }
`

const BoxerImage = styled.img`
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  border: 1px solid #bca573;
`

const BoxerName = styled.p`
  width: 94%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
