import React from 'react'
import styled from 'styled-components'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import BaseLayout from 'components/layouts/base_layout'

export default function Admins(props: any) {
  let {
    users,
    classics,
    bouts,
    recommendations,
    fights,
    fights_recent,
    fights_today,
    fights_month,
    fights_year,
    latest_fights,
    user_today,
    user_month,
    user_year,
    user_recent,
    top_users,
    current_user,
  } = props

  const options: Highcharts.Options = {
    colors: ['#bca573'],
    chart: {
      backgroundColor: 'transparent',
    },
    colorAxis: [
      {
        gridLineColor: 'red',
      },
    ],
    title: {
      text: '',
    },
    xAxis: {
      categories: fights_recent,
      title: {
        text: 'Last 6 months',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
      title: {
        text: 'No. of fights',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    series: [
      {
        type: 'line',
        data: fights_recent,
        name: 'scorecards',
      },
    ],
  }

  const user_options: Highcharts.Options = {
    colors: ['#bca573'],
    chart: {
      backgroundColor: 'transparent',
    },
    colorAxis: [
      {
        gridLineColor: 'red',
      },
    ],
    title: {
      text: '',
    },
    xAxis: {
      categories: user_recent,
      title: {
        text: 'Last 6 months',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
      title: {
        text: 'No. of users',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    series: [
      {
        type: 'line',
        data: user_recent,
        name: 'users',
      },
    ],
  }

  return (
    <>
      <BaseLayout title='Admin' current_user={current_user}>
        <TopSection>
          <BigStat>
            <Big>{users}</Big>
            <Small>Users</Small>
          </BigStat>
          <BigStat>
            <Big>{fights}</Big>
            <Small>Fights</Small>
          </BigStat>
          <BigStat>
            <Big>{bouts}</Big>
            <Small>Bouts</Small>
          </BigStat>
          <BigStat>
            <Big>{recommendations}</Big>
            <Small>
              Scheduled <Link href='/fights/bouts/all/'>new</Link>
            </Small>
          </BigStat>
          <BigStat>
            <Big>{classics}</Big>
            <Small>Classics</Small>
          </BigStat>
        </TopSection>

        <Charts>
          <ChartWrapper>
            <ChartsHeader>
              <Title>Users</Title>

              <Stats>
                Today {user_today} | Month {user_month} | Year {user_year}
              </Stats>
            </ChartsHeader>
            <HighchartsReact
              highcharts={Highcharts}
              options={user_options}
              {...props}
            />
          </ChartWrapper>

          <ChartWrapper>
            <ChartsHeader>
              <Title>Fights</Title>
              <Stats>
                Today {fights_today} | Month {fights_month} | Year {fights_year}
              </Stats>
            </ChartsHeader>
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              {...props}
            />
          </ChartWrapper>
        </Charts>

        <FlexHalf>
          <div>
            <SectionTitle>Recent fights</SectionTitle>
            <Table>
              <tbody>
                {latest_fights.map((fight, index) => (
                  <tr key={index}>
                    <td>{moment(fight.created_at).fromNow()}</td>
                    <NumberCell>{fight.user_id}</NumberCell>
                    <td>
                      <a href={`/scorecards/${fight.id}`}>
                        {fight.boxerA} vs {fight.boxerB}
                        <br />
                        <SubTitle>
                          {fight.boxerATotal} - {fight.boxerBTotal}
                          {fight.postWinner == 'nil'
                            ? ''
                            : ` | ${fight.postWinner}`}
                        </SubTitle>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div>
            <SectionTitle>Hall of fame</SectionTitle>

            <Table>
              <tbody>
                {top_users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.email}</td>
                    <NumberCell>{user.fight_count}</NumberCell>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </FlexHalf>
      </BaseLayout>
    </>
  )
}

const BigStat = styled.div`
  background: #141419;
  border: 1px solid #282828;
  border-radius: 0.5rem;
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  color: white;
  text-align: center;
  width: 100%;
`

const Big = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 700;
`

const Small = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(152, 152, 152, 1);
`

const TopSection = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  padding-bottom: 2rem;
`

const ChartWrapper = styled.div`
  display: grid;
  height: 14rem;
  background: #141419;
  border: 1px solid #282828;
  border-radius: 0.5rem;
  padding: 0 2rem;
  grid-template-rows: 3rem 1fr;
  row-gap: 1rem;
  width: 100%;

  .highcharts-credits,
  .highcharts-legend {
    display: none;
  }
`

const ChartsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: space-between;
  align-items: center;
`

const Charts = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`

const Title = styled.h3`
  color: #fff;
  font-size: 1rem;
`

const Stats = styled.p`
  color: rgba(152, 152, 152, 1);
  text-align: right;
`

const Table = styled.table`
  margin-top: 1rem;
`

const NumberCell = styled.td`
  text-align: right;
`

const SubTitle = styled.p`
  color: rgba(152, 152, 152, 1);
  font-size: 14px;
  margin: 0;
`

const SectionTitle = styled.h3`
  margin-top: 4rem;
  color: #fff;
  font-weight: 500;
`
const FlexHalf = styled.div`
  display: flex;
  width: 100%;

  > div {
    flex: 1;
  }
`

const Link = styled.a`
  text-align: left;
  color: #bca573;
`
