import React, { Fragment, FunctionComponent, useState, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../../utils/media-queries'

import BaseLayout from 'components/layouts/base_layout'
import RegularButton from 'components/buttons/regular_button'
import {
  FightFormWide,
  FightFormFieldTextArea,
  FightFormFieldSelect,
} from 'components/forms'
import { DateDisplay } from 'components/date_display'

export default function Scorecard(props: any) {
  let { scorecard, csrf_param, csrf_token, current_user } = props
  let roundsUI
  let mobileRoundsUI

  const [notesValue, setNotesValue] = useState(scorecard.notes)
  const [winnerValue, setWinnerValue] = useState(scorecard.postWinner)
  const [resultValue, setResultValue] = useState(scorecard.postResult)
  const [resultRndValue, setResultRndValue] = useState(scorecard.postResultRnd)
  const [boxerARnd1, setBoxerARnd1] = useState(scorecard.boxerARnd1)
  const [boxerARnd2, setBoxerARnd2] = useState(scorecard.boxerARnd2)
  const [boxerARnd3, setBoxerARnd3] = useState(scorecard.boxerARnd3)
  const [boxerARnd4, setBoxerARnd4] = useState(scorecard.boxerARnd4)
  const [boxerARnd5, setBoxerARnd5] = useState(scorecard.boxerARnd5)
  const [boxerARnd6, setBoxerARnd6] = useState(scorecard.boxerARnd6)
  const [boxerARnd7, setBoxerARnd7] = useState(scorecard.boxerARnd7)
  const [boxerARnd8, setBoxerARnd8] = useState(scorecard.boxerARnd8)
  const [boxerARnd9, setBoxerARnd9] = useState(scorecard.boxerARnd9)
  const [boxerARnd10, setBoxerARnd10] = useState(scorecard.boxerARnd10)
  const [boxerARnd11, setBoxerARnd11] = useState(scorecard.boxerARnd11)
  const [boxerARnd12, setBoxerARnd12] = useState(scorecard.boxerARnd12)
  const [boxerARnd13, setBoxerARnd13] = useState(scorecard.boxerARnd13)
  const [boxerARnd14, setBoxerARnd14] = useState(scorecard.boxerARnd14)
  const [boxerARnd15, setBoxerARnd15] = useState(scorecard.boxerARnd15)
  const [boxerATotal, setBoxerATotal] = useState(scorecard.boxerATotal)
  const [boxerBRnd1, setBoxerBRnd1] = useState(scorecard.boxerBRnd1)
  const [boxerBRnd2, setBoxerBRnd2] = useState(scorecard.boxerBRnd2)
  const [boxerBRnd3, setBoxerBRnd3] = useState(scorecard.boxerBRnd3)
  const [boxerBRnd4, setBoxerBRnd4] = useState(scorecard.boxerBRnd4)
  const [boxerBRnd5, setBoxerBRnd5] = useState(scorecard.boxerBRnd5)
  const [boxerBRnd6, setBoxerBRnd6] = useState(scorecard.boxerBRnd6)
  const [boxerBRnd7, setBoxerBRnd7] = useState(scorecard.boxerBRnd7)
  const [boxerBRnd8, setBoxerBRnd8] = useState(scorecard.boxerBRnd8)
  const [boxerBRnd9, setBoxerBRnd9] = useState(scorecard.boxerBRnd9)
  const [boxerBRnd10, setBoxerBRnd10] = useState(scorecard.boxerBRnd10)
  const [boxerBRnd11, setBoxerBRnd11] = useState(scorecard.boxerBRnd11)
  const [boxerBRnd12, setBoxerBRnd12] = useState(scorecard.boxerBRnd12)
  const [boxerBRnd13, setBoxerBRnd13] = useState(scorecard.boxerBRnd13)
  const [boxerBRnd14, setBoxerBRnd14] = useState(scorecard.boxerBRnd14)
  const [boxerBRnd15, setBoxerBRnd15] = useState(scorecard.boxerBRnd15)
  const [boxerBTotal, setBoxerBTotal] = useState(scorecard.boxerBTotal)

  useEffect(() => {
    UpdateBoxerATotal()
    UpdateBoxerBTotal()
  })

  interface ScorecardWrapperProps {
    round: number
    aValue: any
    setAValue: any
    bValue: any
    setBValue: any
  }
  const ScorecardWrapper: FunctionComponent<ScorecardWrapperProps> = ({
    round,
    aValue,
    setAValue,
    bValue,
    setBValue,
  }) => {
    return (
      <ScorecardRoundWrapper>
        <ScorecardTopRow>{round}</ScorecardTopRow>
        <ScorecardRow>
          <ScorecardButton
            onClick={() => {
              uploadData(aValue, setAValue)
            }}
            onChange={() => {
              uploadData(aValue, setAValue)
            }}>
            {aValue}
          </ScorecardButton>
        </ScorecardRow>
        <ScorecardRow>
          <ScorecardButton
            onClick={() => {
              uploadData(bValue, setBValue)
            }}>
            {bValue}
          </ScorecardButton>
        </ScorecardRow>
      </ScorecardRoundWrapper>
    )
  }

  const MobileScorecardWrapper: FunctionComponent<ScorecardWrapperProps> = ({
    round,
    aValue,
    setAValue,
    bValue,
    setBValue,
  }) => {
    return (
      <MobileScorecardRoundWrapper>
        <ScorecardButton
          onClick={() => {
            uploadData(aValue, setAValue)
          }}
          onChange={() => {
            uploadData(aValue, setAValue)
          }}>
          {aValue}
        </ScorecardButton>
        <MobileScorecardRnd>{round}</MobileScorecardRnd>
        <ScorecardButton
          onClick={() => {
            uploadData(bValue, setBValue)
          }}>
          {bValue}
        </ScorecardButton>
      </MobileScorecardRoundWrapper>
    )
  }

  if (scorecard.rounds == 3) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
      </>
    )
  } else if (scorecard.rounds == 4) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
      </>
    )
  } else if (scorecard.rounds == 5) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
      </>
    )
  } else if (scorecard.rounds == 6) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
      </>
    )
  } else if (scorecard.rounds == 8) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <ScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <ScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
      </>
    )
  } else if (scorecard.rounds == 10) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <ScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <ScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <ScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <ScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
      </>
    )
  } else if (scorecard.rounds == 12) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <ScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <ScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <ScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <ScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
        <ScorecardWrapper
          round={11}
          aValue={boxerARnd11}
          setAValue={setBoxerARnd11}
          bValue={boxerBRnd11}
          setBValue={setBoxerBRnd11}
        />
        <ScorecardWrapper
          round={12}
          aValue={boxerARnd12}
          setAValue={setBoxerARnd12}
          bValue={boxerBRnd12}
          setBValue={setBoxerBRnd12}
        />
      </>
    )
  } else if (scorecard.rounds == 15) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <ScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <ScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <ScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <ScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
        <ScorecardWrapper
          round={11}
          aValue={boxerARnd11}
          setAValue={setBoxerARnd11}
          bValue={boxerBRnd11}
          setBValue={setBoxerBRnd11}
        />
        <ScorecardWrapper
          round={12}
          aValue={boxerARnd12}
          setAValue={setBoxerARnd12}
          bValue={boxerBRnd12}
          setBValue={setBoxerBRnd12}
        />
        <ScorecardWrapper
          round={13}
          aValue={boxerARnd13}
          setAValue={setBoxerARnd13}
          bValue={boxerBRnd13}
          setBValue={setBoxerBRnd13}
        />
        <ScorecardWrapper
          round={14}
          aValue={boxerARnd14}
          setAValue={setBoxerARnd14}
          bValue={boxerBRnd14}
          setBValue={setBoxerBRnd14}
        />
        <ScorecardWrapper
          round={15}
          aValue={boxerARnd15}
          setAValue={setBoxerARnd15}
          bValue={boxerBRnd15}
          setBValue={setBoxerBRnd15}
        />
      </>
    )
  }

  if (scorecard.rounds == 3) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
      </>
    )
  } else if (scorecard.rounds == 4) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
      </>
    )
  } else if (scorecard.rounds == 5) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
      </>
    )
  } else if (scorecard.rounds == 6) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
      </>
    )
  } else if (scorecard.rounds == 8) {
    roundsUI = (
      <>
        <ScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <ScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <ScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <ScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <ScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <ScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <ScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <ScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
      </>
    )
  } else if (scorecard.rounds == 10) {
    mobileRoundsUI = (
      <>
        <MobileScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <MobileScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <MobileScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <MobileScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <MobileScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <MobileScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <MobileScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <MobileScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <MobileScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <MobileScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
      </>
    )
  } else if (scorecard.rounds == 12) {
    mobileRoundsUI = (
      <>
        <MobileScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <MobileScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <MobileScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <MobileScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <MobileScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <MobileScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <MobileScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <MobileScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <MobileScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <MobileScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
        <MobileScorecardWrapper
          round={11}
          aValue={boxerARnd11}
          setAValue={setBoxerARnd11}
          bValue={boxerBRnd11}
          setBValue={setBoxerBRnd11}
        />
        <MobileScorecardWrapper
          round={12}
          aValue={boxerARnd12}
          setAValue={setBoxerARnd12}
          bValue={boxerBRnd12}
          setBValue={setBoxerBRnd12}
        />
      </>
    )
  } else if (scorecard.rounds == 15) {
    mobileRoundsUI = (
      <>
        <MobileScorecardWrapper
          round={1}
          aValue={boxerARnd1}
          setAValue={setBoxerARnd1}
          bValue={boxerBRnd1}
          setBValue={setBoxerBRnd1}
        />
        <MobileScorecardWrapper
          round={2}
          aValue={boxerARnd2}
          setAValue={setBoxerARnd2}
          bValue={boxerBRnd2}
          setBValue={setBoxerBRnd2}
        />
        <MobileScorecardWrapper
          round={3}
          aValue={boxerARnd3}
          setAValue={setBoxerARnd3}
          bValue={boxerBRnd3}
          setBValue={setBoxerBRnd3}
        />
        <MobileScorecardWrapper
          round={4}
          aValue={boxerARnd4}
          setAValue={setBoxerARnd4}
          bValue={boxerBRnd4}
          setBValue={setBoxerBRnd4}
        />
        <MobileScorecardWrapper
          round={5}
          aValue={boxerARnd5}
          setAValue={setBoxerARnd5}
          bValue={boxerBRnd5}
          setBValue={setBoxerBRnd5}
        />
        <MobileScorecardWrapper
          round={6}
          aValue={boxerARnd6}
          setAValue={setBoxerARnd6}
          bValue={boxerBRnd6}
          setBValue={setBoxerBRnd6}
        />
        <MobileScorecardWrapper
          round={7}
          aValue={boxerARnd7}
          setAValue={setBoxerARnd7}
          bValue={boxerBRnd7}
          setBValue={setBoxerBRnd7}
        />
        <MobileScorecardWrapper
          round={8}
          aValue={boxerARnd8}
          setAValue={setBoxerARnd8}
          bValue={boxerBRnd8}
          setBValue={setBoxerBRnd8}
        />
        <MobileScorecardWrapper
          round={9}
          aValue={boxerARnd9}
          setAValue={setBoxerARnd9}
          bValue={boxerBRnd9}
          setBValue={setBoxerBRnd9}
        />
        <MobileScorecardWrapper
          round={10}
          aValue={boxerARnd10}
          setAValue={setBoxerARnd10}
          bValue={boxerBRnd10}
          setBValue={setBoxerBRnd10}
        />
        <MobileScorecardWrapper
          round={11}
          aValue={boxerARnd11}
          setAValue={setBoxerARnd11}
          bValue={boxerBRnd11}
          setBValue={setBoxerBRnd11}
        />
        <MobileScorecardWrapper
          round={12}
          aValue={boxerARnd12}
          setAValue={setBoxerARnd12}
          bValue={boxerBRnd12}
          setBValue={setBoxerBRnd12}
        />
        <MobileScorecardWrapper
          round={13}
          aValue={boxerARnd13}
          setAValue={setBoxerARnd13}
          bValue={boxerBRnd13}
          setBValue={setBoxerBRnd13}
        />
        <MobileScorecardWrapper
          round={14}
          aValue={boxerARnd14}
          setAValue={setBoxerARnd14}
          bValue={boxerBRnd14}
          setBValue={setBoxerBRnd14}
        />
        <MobileScorecardWrapper
          round={15}
          aValue={boxerARnd15}
          setAValue={setBoxerARnd15}
          bValue={boxerBRnd15}
          setBValue={setBoxerBRnd15}
        />
      </>
    )
  }

  const IncrementItem = (value, setValue) => {
    if (value <= 10) {
      setValue(value - 1)
    } else if (value == 10) {
      setValue(0)
    }

    if (value == 6) {
      setValue(0)
    }

    if (value == 0) {
      setValue(10)
    }

    return value
  }

  function handleUpdate(e: any) {
    const updatedScorecard = {
      ...scorecard,
      boxerARnd1: boxerARnd1,
      boxerARnd2: boxerARnd2,
      boxerARnd3: boxerARnd3,
      boxerARnd4: boxerARnd4,
      boxerARnd5: boxerARnd5,
      boxerARnd6: boxerARnd6,
      boxerARnd7: boxerARnd7,
      boxerARnd8: boxerARnd8,
      boxerARnd9: boxerARnd9,
      boxerARnd10: boxerARnd10,
      boxerARnd11: boxerARnd11,
      boxerARnd12: boxerARnd12,
      boxerARnd13: boxerARnd13,
      boxerARnd14: boxerARnd14,
      boxerARnd15: boxerARnd15,
      boxerATotal: boxerATotal,
      boxerBRnd1: boxerBRnd1,
      boxerBRnd2: boxerBRnd2,
      boxerBRnd3: boxerBRnd3,
      boxerBRnd4: boxerBRnd4,
      boxerBRnd5: boxerBRnd5,
      boxerBRnd6: boxerBRnd6,
      boxerBRnd7: boxerBRnd7,
      boxerBRnd8: boxerBRnd8,
      boxerBRnd9: boxerBRnd9,
      boxerBRnd10: boxerBRnd10,
      boxerBRnd11: boxerBRnd11,
      boxerBRnd12: boxerBRnd12,
      boxerBRnd13: boxerBRnd13,
      boxerBRnd14: boxerBRnd14,
      boxerBRnd15: boxerBRnd15,
      boxerBTotal: boxerBTotal,
      notes: notesValue,
      postWinner: winnerValue,
      postResult: resultValue,
      postResultRnd: resultRndValue,
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: `/scorecards/${scorecard.id}`,
      type: 'PATCH',
      data: { fight: updatedScorecard },
      success: () => {
        console.log('Scorecard updated')
      },
    })
  }

  const uploadData = async (value, setValue) => {
    IncrementItem(value, setValue)
  }

  const UpdateBoxerATotal = () => {
    setBoxerATotal(
      boxerARnd1 +
        boxerARnd2 +
        boxerARnd3 +
        boxerARnd4 +
        boxerARnd5 +
        boxerARnd6 +
        boxerARnd7 +
        boxerARnd8 +
        boxerARnd9 +
        boxerARnd10 +
        boxerARnd11 +
        boxerARnd12 +
        boxerARnd13 +
        boxerARnd14 +
        boxerARnd15,
    )
  }

  const UpdateBoxerBTotal = () => {
    setBoxerBTotal(
      boxerBRnd1 +
        boxerBRnd2 +
        boxerBRnd3 +
        boxerBRnd4 +
        boxerBRnd5 +
        boxerBRnd6 +
        boxerBRnd7 +
        boxerBRnd8 +
        boxerBRnd9 +
        boxerBRnd10 +
        boxerBRnd11 +
        boxerBRnd12 +
        boxerBRnd13 +
        boxerBRnd14 +
        boxerBRnd15,
    )
  }

  return (
    <Fragment>
      <BaseLayout
        title='Scorecards'
        backButton={true}
        buttonTitle='All Scorecards'
        path='/scorecards/'
        current_user={current_user}>
        <Container>
          <FightFormWide
            className='edit_scorecard'
            id={`edit_scorecard_${scorecard.id}`}
            action={`/scorecards/${scorecard.id}`}
            acceptCharset='UTF-8'
            method='post'>
            <input name='utf8' type='hidden' value='✓' />
            <input type='hidden' name='_method' value='patch' />
            <input type='hidden' name={csrf_param} value={csrf_token} />
            <FightIntro>
              <FightTitle>
                <FightDate>
                  <DateDisplay fight={scorecard} date={scorecard.nsDate} />
                </FightDate>
                <Title>
                  {scorecard.boxerA} vs {scorecard.boxerB}
                </Title>
                <TitleUnderline />
              </FightTitle>
            </FightIntro>

            <FightDetails>
              <ScorecardContainer>
                <ScorecardRoundWrapperName>
                  <ScorecardTopRow />
                  <ScorecardBoxerName>{scorecard.boxerA}</ScorecardBoxerName>
                  <ScorecardBoxerName>{scorecard.boxerB}</ScorecardBoxerName>
                </ScorecardRoundWrapperName>
                {roundsUI}
                <ScorecardRoundWrapper>
                  <ScorecardTopRow>TOTAL</ScorecardTopRow>
                  <ScorecardBoxerTotal>{boxerATotal}</ScorecardBoxerTotal>
                  <ScorecardBoxerTotal>{boxerBTotal}</ScorecardBoxerTotal>
                </ScorecardRoundWrapper>
              </ScorecardContainer>

              <MobileScorecardContainer>
                <MobileScorecardRoundWrapper>
                  <ScorecardBoxerName>
                    {scorecard.boxerA}
                    <MobileTotalScore>{boxerATotal}</MobileTotalScore>
                  </ScorecardBoxerName>
                  <MobileScorecardRnd>vs</MobileScorecardRnd>
                  <ScorecardBoxerName>
                    {scorecard.boxerB}
                    <MobileTotalScore>{boxerBTotal}</MobileTotalScore>
                  </ScorecardBoxerName>
                </MobileScorecardRoundWrapper>
                {mobileRoundsUI}
              </MobileScorecardContainer>

              {/* <ScorecardEdit passChildData={childData} /> */}

              <OfficialScore>
                <label>Official result</label>
                <ResultOptions>
                  <FightFormFieldSelect
                    id='postWinner'
                    name='postWinner'
                    value={winnerValue}
                    onChange={(e) => setWinnerValue(e.target.value)}>
                    <option id='postWinner' value='nil'>
                      -- Select winner --
                    </option>
                    <option id='postWinner' value={scorecard.boxerA}>
                      {scorecard.boxerA}
                    </option>
                    <option id='postWinner' value={scorecard.boxerB}>
                      {scorecard.boxerB}
                    </option>
                    <option id='postWinner' value={'Draw'}>
                      Draw
                    </option>
                  </FightFormFieldSelect>
                  <FightFormFieldSelect
                    id='postResult'
                    name='postResult'
                    value={resultValue}
                    onChange={(e) => setResultValue(e.target.value)}>
                    <option id='postResult' value='nil'>
                      -- Select result --
                    </option>
                    <option id='postResult' value='KO'>
                      KO
                    </option>
                    <option id='postResult' value='TKO'>
                      TKO
                    </option>
                    <option id='postResult' value='DSQ'>
                      DSQ
                    </option>
                    <option id='postResult' value='RTD'>
                      RTD
                    </option>
                    <option id='postResult' value='SD'>
                      Split decision
                    </option>
                    <option id='postResult' value='MD'>
                      Majority decision
                    </option>
                    <option id='postResult' value='UD'>
                      Unanimous decision
                    </option>
                  </FightFormFieldSelect>
                  <FightFormFieldSelect
                    id='postResultRnd'
                    name='postResultRnd'
                    value={resultRndValue}
                    onChange={(e) => setResultRndValue(e.target.value)}>
                    <option id='postResultRnd' value={0}>
                      -- Select round --
                    </option>
                    <option id='postResultRnd' value={1}>
                      1
                    </option>
                    <option id='postResultRnd' value={2}>
                      2
                    </option>
                    <option id='postResultRnd' value={3}>
                      3
                    </option>
                    <option id='postResultRnd' value={4}>
                      4
                    </option>
                    <option id='postResultRnd' value={5}>
                      5
                    </option>
                    <option id='postResultRnd' value={6}>
                      6
                    </option>
                    <option id='postResultRnd' value={7}>
                      7
                    </option>
                    <option id='postResultRnd' value={8}>
                      8
                    </option>
                    <option id='postResultRnd' value={9}>
                      9
                    </option>
                    <option id='postResultRnd' value={10}>
                      10
                    </option>
                    <option id='postResultRnd' value={11}>
                      11
                    </option>
                    <option id='postResultRnd' value={12}>
                      12
                    </option>
                  </FightFormFieldSelect>
                </ResultOptions>
              </OfficialScore>
              <FightNotes>
                <label>Notes</label>
                <FightFormFieldTextArea
                  name='notes'
                  id='notes'
                  value={notesValue}
                  onChange={(e) => setNotesValue(e.target.value)}
                />
              </FightNotes>
              <Actions>
                <RegularButton
                  path={'/scorecards/' + scorecard.id}
                  buttonTitle='Cancel'
                />
                <RegularButton
                  onClick={(event) => handleUpdate(event)}
                  buttonTitle='Save scores'
                  colour='#bca573'
                />
              </Actions>
            </FightDetails>
          </FightFormWide>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-contents: space-between;
  align-items: center;
`

const FightTitle = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr 0.5rem;
`

const FightDate = styled.div`
  color: #989898;
  font-size: 0.875rem;
`

const Title = styled.h1`
  color: white;
  font-size: 34px;
  margin: 0.75rem 0;

  @media ${device.mobileL} {
    font-size: 24px;
  }
`

const TitleUnderline = styled.div`
  height: 2px;
  width: 8rem;
  background: #bca573;
`

const FightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5rem 1fr;
  row-gap: 2rem;

  @media ${device.mobileL} {
    grid-template-rows: 1fr 15rem 20rem;
  }
`

const OfficialScore = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr;
  row-gap: 1rem;
  color: #fff;

  label {
    color: #989898;
    font-size: 0.875rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const ResultOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 3.25rem 3.25rem 3.25rem;
    row-gap: 1rem;
  }
`

const FightNotes = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr;
  row-gap: 1rem;
  color: #fff;
  line-height: 1.4;

  label {
    color: #989898;
    font-size: 0.875rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const Actions = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 7rem 10rem;
  justify-contents: start;
  column-gap: 1rem;
`

const ScorecardRow = styled.div``

const ScorecardButton = styled.button`
  background: #1b1c21;
  border: 1px solid #fff;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  color: #fff;
`

const ScorecardContainer = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 10rem auto;
  column-gap: 0rem;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #313131;
  border-radius: 8px;
  line-height: 3;
  text-align: center;
  background: #141419;
  width: 100%;

  @media ${device.mobileL} {
    display: none;
  }
`

const ScorecardTopRow = styled.p`
  background: #1b1c21;
  margin: 0;

  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`

const ScorecardBoxerName = styled.p`
  margin: 0;
  text-align: left;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.mobileL} {
    text-align: center;
    padding-left: 0rem;
  }
`

const ScorecardBoxerTotal = styled.p`
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ScorecardRoundWrapperName = styled.div`
  display: grid;
  grid-template-rows: 2.6rem 4rem 4rem;
  row-gap: 1rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
`

const ScorecardRoundWrapper = styled.div`
  display: grid;
  grid-template-rows: 2.6rem 4rem 4rem;
  row-gap: 1rem;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
`

const MobileScorecardContainer = styled.section`
  display: none;
  @media ${device.mobileL} {
    display: grid;
    grid-template-columns: auto;
    row-gap: 2rem;
    align-items: center;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid #313131;
    border-radius: 8px;
    line-height: 3;
    text-align: center;
    background: #141419;
    width: 100%;
    padding: 1rem 0 2rem 0;
  }
`

const MobileScorecardRoundWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: auto;
  justify-items: center;
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
`

const MobileScorecardRnd = styled.div`
  text-align: center;
  color: #fff;
  font-size: 0.875rem;
`

const MobileTotalScore = styled.div`
  color: #666;
  margin: 0;
  line-height: 1;
`
