import React, { Fragment } from 'react'
import styled from 'styled-components'
import Layout from 'components/layouts/base_layout'
import { FightListItem } from '../../fight_list_item/fight_list_item'

export default function SearchResults(props: any) {
  let {
    searchable_recommendations,
    searchable_recommendations_count,
    current_user,
    csrf_param,
    csrf_token,
  } = props
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const searchQuery = urlParams.get('query')

  const FightList = ({ searchable_recommendations }) => (
    <List>
      {searchable_recommendations.map((searchable_recommendations: any) => (
        <div key={searchable_recommendations.id}>
          <FightListItem
            boxerA={searchable_recommendations.boxerA}
            boxerB={searchable_recommendations.boxerB}
            date={searchable_recommendations.fightDate}
            rounds={searchable_recommendations.rounds}
            id={searchable_recommendations.id}
            series={searchable_recommendations.series}
            current_user={current_user}
            csrf_param={csrf_param}
            csrf_token={csrf_token}
          />
        </div>
      ))}
    </List>
  )

  return (
    <Fragment>
      <Layout
        title={`Showing ${searchable_recommendations_count} results for '${searchQuery}'`}
        fightSearch={true}
        current_user={current_user}>
        <FightList searchable_recommendations={searchable_recommendations} />
      </Layout>
    </Fragment>
  )
}

const List = styled.ul`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3%;
  row-gap: 2rem;
`
