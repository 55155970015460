import React from 'react'
import styled from 'styled-components'
import { DateDisplay } from 'components/date_display'

export default function ScorecardSummary(props: any) {
  const {
    id,
    date,
    rounds,
    boxerA,
    boxerATotal,
    boxerB,
    boxerBTotal,
    postWinner,
  } = props
  let boxerAUI
  let boxerBUI

  if (postWinner == boxerA) {
    boxerAUI = (
      <>
        {boxerA} <StarIcon>★</StarIcon>
      </>
    )
    boxerBUI = (
      <>
        <LoserSpan>{boxerB}</LoserSpan>
      </>
    )
  } else if (postWinner == boxerB) {
    boxerAUI = <LoserSpan>{boxerA}</LoserSpan>
    boxerBUI = (
      <>
        {boxerB}
        <StarIcon>★</StarIcon>
      </>
    )
  } else {
    boxerAUI = <>{boxerA}</>
    boxerBUI = <>{boxerB}</>
  }

  return (
    <ScorecardWrapper>
      <a href={'/scorecards/' + id}>
        <InnerWrapper>
          <LabelWrapper>
            <LabelDate>
              <DateDisplay fight={props} date={date} />
            </LabelDate>
            <LabelRounds>{rounds} rounds</LabelRounds>
          </LabelWrapper>

          <BoxerWrapper>
            <BoxerName>{boxerAUI}</BoxerName>
            <BoxerScore>{boxerATotal}</BoxerScore>
          </BoxerWrapper>

          <BoxerWrapper>
            <BoxerName>{boxerBUI}</BoxerName>
            <BoxerScore>{boxerBTotal}</BoxerScore>
          </BoxerWrapper>
        </InnerWrapper>
      </a>
    </ScorecardWrapper>
  )
}

const ScorecardWrapper = styled.section`
  transition: 0.3s all ease-in-out;

  &:hover {
    transform: translateY(2px);
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.19);
    transform: scale(1.02, 1.02);

    figure {
      color: #bca573;
    }
  }

  p,
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const InnerWrapper = styled.div`
  transition: 0.4s all ease-in-out;
  padding: 1rem;
  background: #141419;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  display: grid;
  grid-template-columns: auto;
  row-gap: 0.4rem;
`
const LabelWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
`
const LabelDate = styled.figure`
  text-align: left;
  color: rgba(152, 152, 152, 1);
  font-size: 0.875rem;
  margin: 0;
`
const LabelRounds = styled.figure`
  text-align: right;
  color: rgba(152, 152, 152, 1);
  font-size: 0.875rem;
  margin: 0;
`

const BoxerWrapper = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
`

const BoxerName = styled.p`
  color: white;
  margin: 0;
`

const BoxerScore = styled.p`
  color: white;
  margin: 0;
  padding-left: 0.5rem;
`

const StarIcon = styled.span`
  font-size: 0.875rem;
  padding-left: 0.5rem;
`

const LoserSpan = styled.span`
  opacity: 0.6;
`
