import React, { Fragment } from 'react'
import styled from 'styled-components'

export function Round(props: any) {
  const { round, boxerARoundScore, boxerBRoundScore } = props

  const RoundNumber = styled.p`
    background: #1b1c21;
    margin: 0;
  `

  const BoxerAScore = styled.p`
    margin: 0;
    color: ${boxerARoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  const BoxerBScore = styled.p`
    margin: 0;
    color: ${boxerBRoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  return (
    <RoundWrapper>
      <RoundNumber>{round}</RoundNumber>
      <BoxerAScore>{boxerARoundScore}</BoxerAScore>
      <BoxerBScore>{boxerBRoundScore}</BoxerBScore>
    </RoundWrapper>
  )
}

const RoundWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
`

export default function Scorecard(props: any) {
  let { recommendation } = props
  let roundsUI

  if (recommendation.rounds == 3) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 4) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 5) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
      </Fragment>
    )
  }

  if (recommendation.rounds == 6) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={recommendation.boxerARnd6}
          boxerBRoundScore={recommendation.boxerBRnd6}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 8) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={recommendation.boxerARnd6}
          boxerBRoundScore={recommendation.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={recommendation.boxerARnd7}
          boxerBRoundScore={recommendation.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={recommendation.boxerARnd8}
          boxerBRoundScore={recommendation.boxerBRnd8}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 10) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={recommendation.boxerARnd6}
          boxerBRoundScore={recommendation.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={recommendation.boxerARnd7}
          boxerBRoundScore={recommendation.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={recommendation.boxerARnd8}
          boxerBRoundScore={recommendation.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={recommendation.boxerARnd9}
          boxerBRoundScore={recommendation.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={recommendation.boxerARnd10}
          boxerBRoundScore={recommendation.boxerBRnd10}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 12) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={recommendation.boxerARnd6}
          boxerBRoundScore={recommendation.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={recommendation.boxerARnd7}
          boxerBRoundScore={recommendation.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={recommendation.boxerARnd8}
          boxerBRoundScore={recommendation.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={recommendation.boxerARnd9}
          boxerBRoundScore={recommendation.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={recommendation.boxerARnd10}
          boxerBRoundScore={recommendation.boxerBRnd10}
        />
        <Round
          round={11}
          boxerARoundScore={recommendation.boxerARnd11}
          boxerBRoundScore={recommendation.boxerBRnd11}
        />
        <Round
          round={12}
          boxerARoundScore={recommendation.boxerARnd12}
          boxerBRoundScore={recommendation.boxerBRnd12}
        />
      </Fragment>
    )
  }
  if (recommendation.rounds == 15) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={recommendation.boxerARnd1}
          boxerBRoundScore={recommendation.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={recommendation.boxerARnd2}
          boxerBRoundScore={recommendation.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={recommendation.boxerARnd3}
          boxerBRoundScore={recommendation.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={recommendation.boxerARnd4}
          boxerBRoundScore={recommendation.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={recommendation.boxerARnd5}
          boxerBRoundScore={recommendation.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={recommendation.boxerARnd6}
          boxerBRoundScore={recommendation.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={recommendation.boxerARnd7}
          boxerBRoundScore={recommendation.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={recommendation.boxerARnd8}
          boxerBRoundScore={recommendation.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={recommendation.boxerARnd9}
          boxerBRoundScore={recommendation.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={recommendation.boxerARnd10}
          boxerBRoundScore={recommendation.boxerBRnd10}
        />
        <Round
          round={11}
          boxerARoundScore={recommendation.boxerARnd11}
          boxerBRoundScore={recommendation.boxerBRnd11}
        />
        <Round
          round={12}
          boxerARoundScore={recommendation.boxerARnd12}
          boxerBRoundScore={recommendation.boxerBRnd12}
        />
        <Round
          round={13}
          boxerARoundScore={recommendation.boxerARnd13}
          boxerBRoundScore={recommendation.boxerBRnd13}
        />
        <Round
          round={14}
          boxerARoundScore={recommendation.boxerARnd14}
          boxerBRoundScore={recommendation.boxerBRnd14}
        />
        <Round
          round={15}
          boxerARoundScore={recommendation.boxerARnd15}
          boxerBRoundScore={recommendation.boxerBRnd15}
        />
      </Fragment>
    )
  }

  return (
    <Container>
      <RoundWrapper>
        <TopRow />
        <BoxerName>{recommendation.boxerA}</BoxerName>
        <BoxerName>{recommendation.boxerB}</BoxerName>
      </RoundWrapper>
      {roundsUI}
      <RoundWrapper>
        <TopRow>TOTAL</TopRow>
        <TotalScore>{recommendation.boxerATotal}</TotalScore>
        <TotalScore>{recommendation.boxerBTotal}</TotalScore>
      </RoundWrapper>
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 8rem auto;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #313131;
  border-radius: 8px;
  line-height: 3;
  text-align: center;
`

const TopRow = styled.p`
  background: #1b1c21;
  margin: 0;

  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`

const BoxerName = styled.p`
  margin: 0;
  text-align: left;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TotalScore = styled.p`
  margin: 0;
`
