import React, { Fragment, useState } from 'react'
import styled from 'styled-components'

export function Round(props: any) {
  const { round, boxerARoundScore, boxerBRoundScore, onClickAction } = props

  const RoundNumber = styled.p`
    background: #1b1c21;
    margin: 0;
  `

  const BoxerAScore = styled.p`
    margin: 0;
    color: ${boxerARoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  const BoxerBScore = styled.p`
    margin: 0;
    color: ${boxerBRoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  const RoundButton = styled.button`
    background: #1b1c21;
    border: 1px solid #fff;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  `

  return (
    <RoundWrapper>
      <RoundNumber>{round}</RoundNumber>
      <BoxerAScore>
        <RoundButton onClick={onClickAction}>{boxerARoundScore}</RoundButton>
      </BoxerAScore>
      <BoxerBScore>{boxerBRoundScore}</BoxerBScore>
    </RoundWrapper>
  )
}

const RoundWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
`

export default function ScorecardEdit(props: any) {
  let { passChildData } = props
  const [boxerARnd1, setBoxerARnd1] = useState(passChildData.boxerARnd1)

  const IncrementItem = (event, value, setValue) => {
    event.preventDefault()
    if (value < 10) {
      setValue(value + 1)
    } else if (value == 10) {
      setValue(0)
    }
    if (value == 0) {
      setValue(6)
    }
  }

  let roundsUI

  if (passChildData.rounds == 3) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 4) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 5) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={passChildData.boxerARnd5}
          boxerBRoundScore={passChildData.boxerBRnd5}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 6) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={passChildData.boxerARnd5}
          boxerBRoundScore={passChildData.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={passChildData.boxerARnd6}
          boxerBRoundScore={passChildData.boxerBRnd6}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 8) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={passChildData.boxerARnd5}
          boxerBRoundScore={passChildData.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={passChildData.boxerARnd6}
          boxerBRoundScore={passChildData.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={passChildData.boxerARnd7}
          boxerBRoundScore={passChildData.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={passChildData.boxerARnd8}
          boxerBRoundScore={passChildData.boxerBRnd8}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 10) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={passChildData.boxerARnd5}
          boxerBRoundScore={passChildData.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={passChildData.boxerARnd6}
          boxerBRoundScore={passChildData.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={passChildData.boxerARnd7}
          boxerBRoundScore={passChildData.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={passChildData.boxerARnd8}
          boxerBRoundScore={passChildData.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={passChildData.boxerARnd9}
          boxerBRoundScore={passChildData.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={passChildData.boxerARnd10}
          boxerBRoundScore={passChildData.boxerBRnd10}
        />
      </Fragment>
    )
  } else if (passChildData.rounds == 12) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={boxerARnd1}
          onClickAction={() => IncrementItem(event, boxerARnd1, setBoxerARnd1)}
          boxerBRoundScore={passChildData.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={passChildData.boxerARnd2}
          boxerBRoundScore={passChildData.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={passChildData.boxerARnd3}
          boxerBRoundScore={passChildData.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={passChildData.boxerARnd4}
          boxerBRoundScore={passChildData.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={passChildData.boxerARnd5}
          boxerBRoundScore={passChildData.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={passChildData.boxerARnd6}
          boxerBRoundScore={passChildData.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={passChildData.boxerARnd7}
          boxerBRoundScore={passChildData.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={passChildData.boxerARnd8}
          boxerBRoundScore={passChildData.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={passChildData.boxerARnd9}
          boxerBRoundScore={passChildData.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={passChildData.boxerARnd10}
          boxerBRoundScore={passChildData.boxerBRnd10}
        />
        <Round
          round={11}
          boxerARoundScore={passChildData.boxerARnd11}
          boxerBRoundScore={passChildData.boxerBRnd11}
        />
        <Round
          round={12}
          boxerARoundScore={passChildData.boxerARnd12}
          boxerBRoundScore={passChildData.boxerBRnd12}
        />
      </Fragment>
    )
  }

  return (
    <Container>
      <RoundWrapper>
        <TopRow />
        <BoxerName>{passChildData.boxerA}</BoxerName>
        <BoxerName>{passChildData.boxerB}</BoxerName>
      </RoundWrapper>
      {roundsUI}
      <RoundWrapper>
        <TopRow>TOTAL</TopRow>
        <TotalScore>{passChildData.boxerATotal}</TotalScore>
        <TotalScore>{passChildData.boxerBTotal}</TotalScore>
      </RoundWrapper>
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 8rem auto;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #313131;
  border-radius: 8px;
  line-height: 3;
  text-align: center;
  background: #141419;
`

const TopRow = styled.p`
  background: #1b1c21;
  margin: 0;

  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`

const BoxerName = styled.p`
  margin: 0;
  text-align: left;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TotalScore = styled.p`
  margin: 0;
`
