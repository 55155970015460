import React from 'react'
import { ListLayout } from 'components/layouts/base_layout/list_layout'
import moment from 'moment'

export default function SchedulePage(props: any) {
  const { scheduled, current_user } = props
  var currentMonth = moment(new Date()).format('MMMM')

  return (
    <ListLayout
      title='Scheduled fights'
      fightSearch={true}
      fights={scheduled}
      showcaseTitle={`Major fights this ${currentMonth}`}
      listTitle='Full schedule'
      current_user={current_user}></ListLayout>
  )
}
