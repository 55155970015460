import React from 'react'
import styled from 'styled-components'
import { device } from '../../utils/media-queries'

import IconButton from 'components/buttons/icon_button'
// @ts-ignore
import image from '../../../../assets/images/glyph_back.svg'

type TitleProps = {
  title: string
  backButton?: boolean
  boxerSearch?: boolean
  fightSearch?: boolean
  buttonTitle?: string
  path?: string
}

export default function Header(props: TitleProps) {
  let { title, backButton, boxerSearch, fightSearch, buttonTitle, path } = props
  let buttonUI
  let searchUI

  if (backButton) {
    buttonUI = (
      <ButtonBackUI>
        <IconButton path={path} icon={image} buttonTitle={buttonTitle} />
      </ButtonBackUI>
    )
  }

  if (boxerSearch) {
    searchUI = (
      <SearchUI>
        <form
          action='/scorecards/search/results'
          acceptCharset='UTF-8'
          method='get'>
          <input name='utf8' type='hidden' value='✓' />
          <SearchField>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='17'
              viewBox='0 0 18 17'
              fill='none'>
              <path
                opacity='0.4'
                d='M7.64136 13.6196C9.10913 13.6196 10.4626 13.145 11.5701 12.354L15.7361 16.52C15.9294 16.7134 16.1843 16.8101 16.4568 16.8101C17.0281 16.8101 17.4324 16.3706 17.4324 15.8081C17.4324 15.5444 17.3445 15.2983 17.1511 15.105L13.0115 10.9565C13.8816 9.81396 14.4001 8.39893 14.4001 6.86084C14.4001 3.14307 11.3591 0.102051 7.64136 0.102051C3.93237 0.102051 0.882568 3.13428 0.882568 6.86084C0.882568 10.5786 3.92358 13.6196 7.64136 13.6196ZM7.64136 12.1606C4.74097 12.1606 2.34155 9.76123 2.34155 6.86084C2.34155 3.96045 4.74097 1.56104 7.64136 1.56104C10.5417 1.56104 12.9412 3.96045 12.9412 6.86084C12.9412 9.76123 10.5417 12.1606 7.64136 12.1606Z'
                fill='#989898'></path>
            </svg>

            <input
              type='text'
              name='query'
              id='query'
              placeholder='Search scorecards'
            />
          </SearchField>
        </form>
      </SearchUI>
    )
  }

  if (fightSearch) {
    searchUI = (
      <SearchUI>
        <form
          action='/fights/search/results'
          acceptCharset='UTF-8'
          method='get'>
          <input name='utf8' type='hidden' value='✓' />
          <SearchField>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='17'
              viewBox='0 0 18 17'
              fill='none'>
              <path
                opacity='0.4'
                d='M7.64136 13.6196C9.10913 13.6196 10.4626 13.145 11.5701 12.354L15.7361 16.52C15.9294 16.7134 16.1843 16.8101 16.4568 16.8101C17.0281 16.8101 17.4324 16.3706 17.4324 15.8081C17.4324 15.5444 17.3445 15.2983 17.1511 15.105L13.0115 10.9565C13.8816 9.81396 14.4001 8.39893 14.4001 6.86084C14.4001 3.14307 11.3591 0.102051 7.64136 0.102051C3.93237 0.102051 0.882568 3.13428 0.882568 6.86084C0.882568 10.5786 3.92358 13.6196 7.64136 13.6196ZM7.64136 12.1606C4.74097 12.1606 2.34155 9.76123 2.34155 6.86084C2.34155 3.96045 4.74097 1.56104 7.64136 1.56104C10.5417 1.56104 12.9412 3.96045 12.9412 6.86084C12.9412 9.76123 10.5417 12.1606 7.64136 12.1606Z'
                fill='#989898'></path>
            </svg>

            <input
              type='text'
              name='query'
              id='query'
              placeholder='Search all fights'
            />
          </SearchField>
        </form>
      </SearchUI>
    )
  }

  return (
    <div>
      <RowEnds>
        <PageTitle>{title}</PageTitle>
        {buttonUI}
        {searchUI}
      </RowEnds>
      <HorizontalDivide />
    </div>
  )
}

const PageTitle = styled.h1`
  color: white;
  font-size: 2.5rem;

  @media ${device.mobileL} {
    margin: 0.875rem 0;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 90vw;
  }
`

const HorizontalDivide = styled.div`
  border-bottom: 1px solid #3c3c3c;
  display: grid;
  grid-template-columns: auto;
`

const RowEnds = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  margin-top: 2rem;

  @media ${device.mobileL} {
    grid-template-columns: 0;
    grid-template-rows: auto;
    grid-auto-flow: column;
  }
`

const SearchField = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr;
  align-items: center;
  background: #141419;
  border: 1px solid #282828;
  border-radius: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: #fff;

  input {
    -webkit-appearance: none;
    border: none;
    background: transparent;
    padding-left: 1rem;

    &:focus {
      outline: 0;
    }
  }

  &:hover {
    border-color: #bca573;
    transition: border-color 0.5s ease-in-out;
  }

  @media ${device.mobileL} {
    width: 12rem;
    margin-bottom: 2rem;
  }
`

const ButtonBackUI = styled.div`
  @media ${device.mobileL} {
    position: relative;
    top: -3.5rem;
    left: 1rem;
  }
`

const SearchUI = styled.div`
  @media ${device.mobileL} {
    position: relative;
    top: -2.5rem;
    left: 0rem;
  }
`
