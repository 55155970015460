import React from 'react'
import styled from 'styled-components'
import RegularButton from 'components/buttons/regular_button'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css'

export default function ConfirmModal(props: any) {
  const [
    title,
    description,
    confirmButtonTitle,
    confirmButtonOnClick,
    cancelButtonTitle,
  ] = props
  return confirmAlert({
    // eslint-disable-next-line react/display-name
    customUI: ({ onClose }) => {
      return (
        <Container>
          <Title>{title}</Title>
          <Description>{description}</Description>

          <RegularButton onClick={confirmButtonOnClick}>
            {confirmButtonTitle}
          </RegularButton>
          <RegularButton onClick={onClose}>{cancelButtonTitle}</RegularButton>
        </Container>
      )
    },
  })
}

ConfirmModal.defaultProps = {
  title: 'Delete Scorecard?',
  description: 'Are you sure you wish to delete this scorecard?',
  confirmButtonTitle: 'Delete',
  cancelButtonOnTitle: 'Cancel',
}

const Container = styled.div``

const Title = styled.h2``

const Description = styled.p``
