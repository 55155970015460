import React, { Fragment, useState } from 'react'
import DatePicker from 'react-datepicker'
import { device } from '../../../utils/media-queries'
// @ts-ignore
import { confirmAlert } from 'react-confirm-alert'
import styled from 'styled-components'
import BaseLayout from 'components/layouts/base_layout'
import RegularButton from 'components/buttons/regular_button'
import { Switch } from 'components/buttons/switch'

import {
  FightForm,
  FightFormFieldLabel,
  FightFormFieldInput,
  FightFormFieldSelect,
  FightFormField,
  FightFormRowHalf,
  FightFormDateInput,
  FightFormFieldHalfWrapper,
} from 'components/forms'
import { DateDisplay } from 'components/date_display'

export default function EditFight(props: any) {
  let { fight, csrf_param, csrf_token, current_user, boxers } = props
  let seriesUI
  let weightUI
  let switchCheck

  const [boxerAValue, setBoxerAValue] = useState(fight.boxerA)
  const [boxerAIDValue, setBoxerAIDValue] = useState(fight.boxerA_id)
  const [boxerBIDValue, setBoxerBIDValue] = useState(fight.boxerB_id)
  const [boxerBValue, setBoxerBValue] = useState(fight.boxerB)
  const [roundsValue, setRoundsValue] = useState(fight.rounds)
  const [orderValue, setOrderValue] = useState(fight.order)
  const [weightClassValue, setWeightClassValue] = useState(fight.weightClass)
  var fightDate = new Date(fight.fightDate)
  fightDate.setDate(fightDate.getDate() + 1)
  const [dateValue, setDateValue] = useState(fightDate)
  const [seriesValue, setSeriesValue] = useState(fight.series)
  const [isRecommendationActive, setIsRecommendationActiveValue] = useState(
    fight.isActive,
  )
  const [isRecommendationClassic, setIsRecommendationClassicValue] = useState(
    fight.isClassic,
  )
  const [postWinnerValue, setPostWinnerValue] = useState(fight.postWinner)
  const [postResultValue, setPostResultValue] = useState(fight.postResult)
  const [postResultRndValue, setPostResultRndValue] = useState(
    fight.postResultRnd,
  )

  if (seriesValue == 0 || seriesValue == 1) {
    switchCheck = false
  } else {
    switchCheck = true
  }

  const [isRematchChecked, setRematchCheck] = useState(switchCheck)

  function handleDelete(e: any) {
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      method: 'DELETE',
      url: `/fights/${fight.id}`,
      success: function () {
        console.log('Recommendation deleted')
      }.bind(this),
      error: function () {
        alert('Cannot delete requested record: ')
      },
    })
  }

  function deleteSubmit(e: any) {
    e.preventDefault()
    confirmAlert({
      // eslint-disable-next-line react/display-name
      customUI: ({ onClose }) => {
        return (
          <ModalContainer>
            <ModalTitle>Delete recommendation?</ModalTitle>
            <ModalDescription>
              Are you sure you wish to delete {fight.boxerA} vs {fight.boxerB}?
            </ModalDescription>

            <ModalActions>
              <RegularButton buttonTitle='Cancel' onClick={onClose} path='' />
              <RegularButton
                buttonTitle='Delete'
                colour='#bca573'
                onClick={() => {
                  handleDelete(e)
                  onClose()
                }}
              />
            </ModalActions>
          </ModalContainer>
        )
      },
    })
  }

  function handleUpdate(e: any) {
    const updatedRecommendation = {
      boxerA: boxerAValue,
      boxerB: boxerBValue,
      boxerA_id: boxerAIDValue,
      boxerB_id: boxerBIDValue,
      rounds: roundsValue,
      fightDate: dateValue,
      series: seriesValue,
      isActive: isRecommendationActive,
      isClassic: isRecommendationClassic,
      weightClass: weightClassValue,
      order: orderValue,
      postWinner: postWinnerValue,
      postResult: postResultValue,
      postResultRnd: postResultRndValue,
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: `/fights/${fight.id}`,
      type: 'PATCH',
      data: { recommendation: updatedRecommendation },
      success: () => {
        console.log('Fight updated')
      },
    })
  }

  weightUI = (
    <>
      <FightFormFieldLabel htmlFor='weight'>Weight Class</FightFormFieldLabel>
      <FightFormFieldSelect
        id='weight'
        name='weight'
        value={weightClassValue}
        onChange={(e) => setWeightClassValue(e.target.value)}>
        <option id='weight' value=''>
          None set
        </option>
        <option id='weight' value='Heavy'>
          Heavyweight
        </option>
        <option id='weight' value='Cruiser'>
          Cruiserweight
        </option>
        <option id='weight' value='Light Heavy'>
          Light Heavyweight
        </option>
        <option id='weight' value='Super Middle'>
          Super Middleweight
        </option>
        <option id='weight' value='Middle'>
          Middleweight
        </option>
        <option id='weight' value='Super Welter'>
          Light Middleweight
        </option>
        <option id='weight' value='Welter'>
          Welterweight
        </option>
        <option id='weight' value='Super Light'>
          Super Lightweight
        </option>
        <option id='weight' value='Light'>
          Lightweight
        </option>
        <option id='weight' value='Super Feather'>
          Super Featherweight
        </option>
        <option id='weight' value='Feather'>
          Featherweight
        </option>
        <option id='weight' value='Super Bantam'>
          Super Bantamweight
        </option>
        <option id='weight' value='Bantam'>
          Bantamweight
        </option>
        <option id='weight' value='Super Fly'>
          Super Flyweight
        </option>
        <option id='weight' value='Fly'>
          Flyweight
        </option>
        <option id='weight' value='Light Fly'>
          Light Flyweight
        </option>
        <option id='weight' value='Minimum'>
          Minimumweight
        </option>
      </FightFormFieldSelect>
    </>
  )

  if (isRematchChecked) {
    seriesUI = (
      <FightFormFieldSelect
        id='series'
        name='series'
        value={seriesValue}
        onChange={(e) => setSeriesValue(e.target.value)}>
        <option id='series' value={1}>
          0
        </option>
        <option id='series' value={2}>
          2 - Rematch
        </option>
        <option id='series' value={3}>
          3 - Trilogy
        </option>
        <option id='series' value={4}>
          4 - Quadrilogy
        </option>
      </FightFormFieldSelect>
    )
  } else {
    seriesUI = ''
  }

  return (
    <Fragment>
      <BaseLayout
        title='Edit scheduled fight'
        backButton={true}
        buttonTitle='Schedule'
        path='/fights/'
        current_user={current_user}>
        <Container>
          <FightIntro>
            <FightTitle>
              <FightDate>
                <DateDisplay fight={fight} date={fight.fightDate} />
              </FightDate>
              <Title>
                {fight.boxerA} vs {fight.boxerB}
              </Title>
              <TitleUnderline />
            </FightTitle>
          </FightIntro>

          <FightDetails>
            <FightForm
              className='edit_recommendation'
              id={`edit_recommendation_${fight.id}`}
              action={`/fights/${fight.id}`}
              acceptCharset='UTF-8'
              method='post'>
              <input name='utf8' type='hidden' value='✓' />
              <input type='hidden' name='_method' value='patch' />
              <input type='hidden' name={csrf_param} value={csrf_token} />
              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerA'>
                    Boxer A name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerA'
                    id='boxerA'
                    type='text'
                    value={boxerAValue}
                    onChange={(e) => setBoxerAValue(e.target.value)}
                  />
                </FightFormField>

                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerB'>
                    Boxer B name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerB'
                    id='boxerB'
                    type='text'
                    value={boxerBValue}
                    onChange={(e) => setBoxerBValue(e.target.value)}
                  />
                </FightFormField>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerA_id'>
                    Boxer A ID ?
                  </FightFormFieldLabel>
                  <FightFormFieldSelect
                    name='boxerA_id'
                    id='boxerA_id'
                    value={boxerAIDValue}
                    onChange={(e) => setBoxerAIDValue(e.target.value)}>
                    <option id='boxerA_id' value={null} key={null}>
                      None selected
                    </option>
                    {boxers.map((boxer, index) => (
                      <option id='boxerA_id' value={boxer.id} key={index}>
                        {boxer.firstname} {boxer.surname}
                      </option>
                    ))}
                  </FightFormFieldSelect>
                </FightFormField>

                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerB_id'>
                    Boxer B ID?
                  </FightFormFieldLabel>
                  <FightFormFieldSelect
                    name='boxerB_id'
                    id='boxerB_id'
                    value={boxerBIDValue}
                    onChange={(e) => setBoxerBIDValue(e.target.value)}>
                    <option id='boxerB_id' value={null} key={null}>
                      None selected
                    </option>
                    {boxers.map((boxer, index) => (
                      <option id='boxerB_id' value={boxer.id} key={index}>
                        {boxer.firstname} {boxer.surname}
                      </option>
                    ))}
                  </FightFormFieldSelect>
                </FightFormField>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormFieldHalfWrapper>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='rounds'>
                      Scheduled Rounds
                    </FightFormFieldLabel>

                    <FightFormFieldSelect
                      id='rounds'
                      name='rounds'
                      value={roundsValue}
                      onChange={(e) => setRoundsValue(e.target.value)}>
                      <option id='rounds' value={3}>
                        3
                      </option>
                      <option id='rounds' value={4}>
                        4
                      </option>
                      <option id='rounds' value={5}>
                        5
                      </option>
                      <option id='rounds' value={6}>
                        6
                      </option>
                      <option id='rounds' value={8}>
                        8
                      </option>
                      <option id='rounds' value={10}>
                        10
                      </option>
                      <option id='rounds' value={12}>
                        12
                      </option>
                      <option id='rounds' value={15}>
                        15
                      </option>
                    </FightFormFieldSelect>
                  </FightFormField>
                  <FightFormField>{weightUI}</FightFormField>
                </FightFormFieldHalfWrapper>

                <FightFormField>
                  <FightFormFieldLabel>Fight date</FightFormFieldLabel>
                  <FightFormDateInput>
                    <DatePicker
                      dateFormat='yyyy-MM-dd'
                      selected={dateValue}
                      onChange={(date) => setDateValue(date)}
                    />
                  </FightFormDateInput>
                </FightFormField>
              </FightFormRowHalf>
              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='recommendation_id'>
                    Rematch
                  </FightFormFieldLabel>

                  <RematchContainer>
                    <Switch
                      checked={isRematchChecked}
                      onChange={(e) => setRematchCheck(e.target.checked)}
                    />
                    {seriesUI}
                  </RematchContainer>
                </FightFormField>

                <FightFormFieldHalfWrapper>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='recommendation_isActive'>
                      Active
                    </FightFormFieldLabel>
                    <RematchContainer>
                      <Switch
                        checked={isRecommendationActive}
                        onChange={(e) =>
                          setIsRecommendationActiveValue(e.target.checked)
                        }
                      />
                    </RematchContainer>
                  </FightFormField>

                  <FightFormField>
                    <FightFormFieldLabel htmlFor='recommendation_isClassic'>
                      Classic
                    </FightFormFieldLabel>

                    <RematchContainer>
                      <Switch
                        checked={isRecommendationClassic}
                        onChange={(e) =>
                          setIsRecommendationClassicValue(e.target.checked)
                        }
                      />
                    </RematchContainer>
                  </FightFormField>
                </FightFormFieldHalfWrapper>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormFieldHalfWrapper>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='postWinner'>
                      Winner
                    </FightFormFieldLabel>
                    <FightFormFieldSelect
                      id='postWinner'
                      name='postWinner'
                      value={postWinnerValue}
                      onChange={(e) => setPostWinnerValue(e.target.value)}>
                      <option id='postResult' value={'nil'}>
                        None specified
                      </option>
                      <option id='postWinner' value={boxerAValue}>
                        {boxerAValue}
                      </option>
                      <option id='postWinner' value={boxerBValue}>
                        {boxerBValue}
                      </option>
                      <option id='postWinner' value='Draw'>
                        Draw
                      </option>
                    </FightFormFieldSelect>
                  </FightFormField>

                  <FightFormField>
                    <FightFormFieldLabel htmlFor='postWinner'>
                      Daily Order
                    </FightFormFieldLabel>
                    <FightFormFieldSelect
                      id='order'
                      name='order'
                      value={orderValue}
                      onChange={(e) => setOrderValue(e.target.value)}>
                      <option id='order' value={1}>
                        1
                      </option>
                      <option id='order' value={2}>
                        2
                      </option>
                      <option id='order' value={3}>
                        3
                      </option>
                      <option id='order' value={4}>
                        4
                      </option>
                      <option id='order' value={5}>
                        5
                      </option>
                      <option id='order' value={6}>
                        6
                      </option>
                      <option id='order' value={7}>
                        7
                      </option>
                      <option id='order' value={8}>
                        8
                      </option>
                    </FightFormFieldSelect>
                  </FightFormField>
                </FightFormFieldHalfWrapper>
                <FightFormFieldHalfWrapper>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='postResult'>
                      Result
                    </FightFormFieldLabel>
                    <FightFormFieldSelect
                      id='postResult'
                      name='postResult'
                      value={postResultValue}
                      onChange={(e) => setPostResultValue(e.target.value)}>
                      <option id='postResult' value={'nil'}>
                        None specified
                      </option>
                      <option id='postResult' value={'KO'}>
                        KO
                      </option>
                      <option id='postResult' value={'TKO'}>
                        TKO
                      </option>
                      <option id='postResult' value={'RTD'}>
                        Retired
                      </option>
                      <option id='postResult' value={'DSQ'}>
                        Disqualified
                      </option>
                      <option id='postResult' value={'UD'}>
                        Unanimous decision
                      </option>
                      <option id='postResult' value={'MD'}>
                        Majority decision
                      </option>
                      <option id='postResult' value={'SD'}>
                        Split decision
                      </option>
                    </FightFormFieldSelect>
                  </FightFormField>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='postResult'>
                      Round
                    </FightFormFieldLabel>
                    <FightFormFieldSelect
                      id='postResultRnd'
                      name='postResultRnd'
                      value={postResultRndValue}
                      onChange={(e) => setPostResultRndValue(e.target.value)}>
                      <option id='postResultRnd' value={0}>
                        None specified
                      </option>
                      <option id='postResultRnd' value={1}>
                        1
                      </option>
                      <option id='postResultRnd' value={2}>
                        2
                      </option>
                      <option id='postResultRnd' value={3}>
                        3
                      </option>
                      <option id='postResultRnd' value={4}>
                        4
                      </option>
                      <option id='postResultRnd' value={5}>
                        5
                      </option>
                      <option id='postResultRnd' value={6}>
                        6
                      </option>
                      <option id='postResultRnd' value={7}>
                        7
                      </option>
                      <option id='postResultRnd' value={8}>
                        8
                      </option>
                      <option id='postResultRnd' value={9}>
                        9
                      </option>
                      <option id='postResultRnd' value={10}>
                        10
                      </option>
                      <option id='postResultRnd' value={11}>
                        11
                      </option>
                      <option id='postResultRnd' value={12}>
                        12
                      </option>
                      <option id='postResultRnd' value={13}>
                        13
                      </option>
                      <option id='postResultRnd' value={14}>
                        14
                      </option>
                      <option id='postResultRnd' value={15}>
                        15
                      </option>
                    </FightFormFieldSelect>
                  </FightFormField>
                </FightFormFieldHalfWrapper>
              </FightFormRowHalf>
              <Actions>
                <RegularButton
                  onClick={(e) => deleteSubmit(e)}
                  buttonTitle='Delete'
                  colour='#17181d'
                />
                <RegularButton
                  path={'/fights/' + fight.id}
                  buttonTitle='Cancel'
                />
                <RegularButton
                  buttonTitle='Update fight'
                  onClick={(event) => handleUpdate(event)}
                  colour='#bca573'
                />
              </Actions>
            </FightForm>
          </FightDetails>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;

  @media ${device.mobileL} {
    row-gap: 0rem;
    grid-template-rows: 7rem 1fr;
  }
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-contents: space-between;
  align-items: center;
`

const FightTitle = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr 0.5rem;
`

const FightDate = styled.div`
  color: #989898;
  font-size: 0.875rem;
`

const Title = styled.h1`
  color: white;
  font-size: 34px;
  margin: 0.75rem 0;

  @media ${device.mobileL} {
    font-size: 24px;
  }
`

const TitleUnderline = styled.div`
  height: 2px;
  width: 8rem;
  background: #bca573;
`

const FightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem 1fr;
  row-gap: 2rem;
`

const Actions = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 7rem 10rem 10rem;
  justify-content: end;
  column-gap: 1rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 3.25rem 3.25rem 3.25rem;
  }
`

const RematchContainer = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
`
// Modal
const ModalContainer = styled.div`
  background: #2a2b2f;
  color: #fff;
  border: 1px solid rgba(152, 152, 152, 0.2);
  padding: 0rem 2rem;
  display: grid;
  grid-template-rows: 4rem 4rem 4rem;
  border-radius: 6px;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.6);
`

const ModalTitle = styled.h2`
  font-size: 32px;
`

const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
`

const ModalActions = styled.div`
  display: grid;
  grid-template-columns: 10rem 10rem;
  justify-content: end;
  column-gap: 2rem;
`
