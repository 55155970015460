import React from 'react'
import styled from 'styled-components'
import FormattedDate from '../utils/date'
import { device } from '../utils/media-queries'
import moment from 'moment'

export default function PastFight(props: any) {
  const { fight, boxer_name } = props
  let fighterName
  let fightSummary
  var todaysDate = moment(new Date()).format('YYYY-MM-DD')

  if (boxer_name == fight.boxerA) {
    fighterName = fight.boxerB
  } else {
    fighterName = fight.boxerA
  }

  if (fight.fightDate < todaysDate) {
    if (fight.postWinner == boxer_name) {
      fightSummary = (
        <span className='win'>
          Won {fight.postResult} {fight.postResultRnd}
        </span>
      )
    } else if (fight.postWinner == 'Draw') {
      fightSummary = <span className='draw'>Draw {fight.postResultRnd}</span>
    } else if (fight.postWinner == '') {
      fightSummary = ``
    } else if (fight.postWinner != boxer_name) {
      fightSummary = (
        <span className='lose'>
          Lost {fight.postResult} {fight.postResultRnd}
        </span>
      )
    }
  } else {
    fightSummary = <span className='win'>Scheduled fight</span>
  }

  return (
    <PastFightSummary href={'/fights/' + fight.id}>
      <TopRow>
        <FightName>
          <p>
            {fighterName} &nbsp; {fightSummary}
          </p>
        </FightName>
        <FightDate>
          <p>
            <FormattedDate dateString={fight.fightDate} />
          </p>
        </FightDate>
      </TopRow>
      <BottomRow>
        The average score from the <span>{fight.fight_count}</span> users who
        scored this fight was{' '}
        <span>
          {fight.boxerATotal} - {fight.boxerBTotal}
        </span>
      </BottomRow>
    </PastFightSummary>
  )
}

const PastFightSummary = styled.a`
  display: grid;
  grid-auto-flow: column;
  padding: 1.2rem 1rem;
  border: 1px #414141 solid;
  grid-template-columns: 1fr;
  border-radius: 4px;

  &:hover {
    background: #1b1c21;
    transition: 0.2s ease-in-out;
  }
`

const TopRow = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-row: 1;
  p {
    margin: 0 0 0.5rem;
  }

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`

const BottomRow = styled.p`
  width: 100%;
  color: #989898;
  line-height: 1.5;
  margin: 0;
  span {
    font-weight: bold;
    color: #fff;
  }
  grid-row: 2;
`

const FightName = styled.div`
  font-weight: 500;
  color: #fff;

  .win {
    color: #78aa70;
  }

  .lose {
    color: rgb(156, 64, 64);
  }

  .draw {
    color: rgb(192, 165, 108);
  }

  @media ${device.mobileL} {
    display: flex;
    width: 100%;
    .win,
    .lose,
    .draw {
      text-align: right;
    }
  }
`

const FightDate = styled.div`
  text-align: right;
  color: #989898;

  @media ${device.mobileL} {
    text-align: left;
    padding-bottom: 0.5rem;
  }
`
