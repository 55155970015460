import React from 'react'
import { parseISO, format } from 'date-fns'

interface Props {
  dateString: any
}

export default function FormattedDate(props: Props): React.ReactElement {
  const date = parseISO(props.dateString)
  return <time dateTime={props.dateString}>{format(date, 'LLL d, yyyy')}</time>
}
