import React, { Fragment } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { confirmAlert } from 'react-confirm-alert'

import Layout from 'components/layouts/base_layout'
import UserStreak from 'components/users/streaks'
import UserDecade from 'components/users/decades'
import RegularButton from 'components/buttons/regular_button'

// @ts-ignore
import profileImage from '../../../../assets/images/user.svg'
// @ts-ignore
import starImage from '../../../../assets/images/glyph_star.svg'
// @ts-ignore
import upImage from '../../../../assets/images/glyph_up.svg'
// @ts-ignore
import downImage from '../../../../assets/images/glyph_down.svg'

export default function Profile(props: any) {
  let {
    user,
    current_user,
    member_since,
    fights_this_month,
    fights_last_month,
    fights_sixties,
    fights_seventies,
    fights_eighties,
    fights_nineties,
    fights_naughties,
    fights_teens,
    fights_twenties,
    fights_recent,
    fights_today,
    fights_month,
    fights_year,
  } = props
  let streaksUI
  let streakMonthUI
  let streakHistorianUI

  if (fights_this_month > fights_last_month) {
    streakMonthUI = (
      <UserStreak
        image={upImage}
        text={`You've scored ${
          fights_this_month - fights_last_month
        } more fights this month than last.`}
      />
    )
  } else if (fights_this_month < fights_last_month) {
    streakMonthUI = (
      <UserStreak
        image={downImage}
        text={`You've scored ${
          fights_last_month - fights_this_month
        } less fights this month than last.`}
      />
    )
  } else {
    streakMonthUI = (
      <UserStreak
        image={downImage}
        text={`You've scored the same number of fights this month than last.`}
      />
    )
  }

  if (fights_sixties + fights_seventies + fights_eighties > 19) {
    streakHistorianUI = (
      <UserStreak
        image={starImage}
        text='Historian! More than 20 scorecards dating from before 1990.'
      />
    )
  }
  if (user.fight_count > 100) {
    streaksUI = (
      <UserStreak image={starImage} text='Legend! Over 100 fights scored!' />
    )
  }

  const options: Highcharts.Options = {
    colors: ['#bca573'],
    chart: {
      backgroundColor: 'transparent',
    },
    colorAxis: [
      {
        gridLineColor: 'red',
      },
    ],
    title: {
      text: '',
    },
    xAxis: {
      categories: fights_recent,
      title: {
        text: 'Last 6 months',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
      title: {
        text: 'No. of fights',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    series: [
      {
        type: 'line',
        data: fights_recent,
        name: 'scorecards',
      },
    ],
  }

  function handleSignout(e: any) {
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      method: 'DELETE',
      url: '/users/sign_out/',
      success: function () {
        console.log('Signed out successfully')
        window.location.href = '/users/sign_in'
      }.bind(this),
      error: function (response, status, error) {
        alert(`Cannot delete requested record: ${response} ${status} ${error}`)
      },
    })
  }

  function deleteSubmit(e: any) {
    e.preventDefault()
    confirmAlert({
      // eslint-disable-next-line react/display-name
      customUI: ({ onClose }) => {
        return (
          <ModalContainer>
            <ModalTitle>Sign out?</ModalTitle>
            <ModalDescription>
              Are you sure you wish to sign out?
            </ModalDescription>

            <ModalActions>
              <RegularButton buttonTitle='Cancel' onClick={onClose} path='' />
              <RegularButton
                buttonTitle='Sign out'
                colour='#bca573'
                onClick={() => {
                  handleSignout(e)
                }}
                path=''
              />
            </ModalActions>
          </ModalContainer>
        )
      },
    })
  }

  return (
    <Fragment>
      <Layout title={`Profile`} current_user={current_user}>
        <UserInfo>
          <Avatar src={profileImage}></Avatar>
          <Info>
            <p>
              {user.email} <br />
              <span>Joined on {member_since}</span>
            </p>
          </Info>
        </UserInfo>

        <ChartWrapper>
          <ChartsHeader>
            <Title>Scorecards</Title>
            <Stats>
              Today {fights_today} | Month {fights_month} | Year {fights_year}
            </Stats>
          </ChartsHeader>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            {...props}
          />
        </ChartWrapper>

        <FightStreaks>
          <TotalScorecards>
            <p>{user.fight_count}</p>
            <span>Scorecards</span>
          </TotalScorecards>
          <Streak>
            {streakMonthUI}
            {streaksUI}
            {streakHistorianUI}
          </Streak>
        </FightStreaks>

        <HorizontalDivide />

        <HistoricalData>
          <h3>Historical data</h3>
          <div />
          <UserDecade
            decade='2020s'
            decadeFights={fights_twenties}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='2010s'
            decadeFights={fights_teens}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='2000s'
            decadeFights={fights_naughties}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='1990s'
            decadeFights={fights_nineties}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='1980s'
            decadeFights={fights_eighties}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='1970s'
            decadeFights={fights_seventies}
            totalFights={user.fight_count}
          />
          <UserDecade
            decade='1960s'
            decadeFights={fights_sixties}
            totalFights={user.fight_count}
          />
        </HistoricalData>

        <Actions>
          <RegularButton path='/users/edit/' buttonTitle='Edit profile' />
          <RegularButton
            onClick={(e) => deleteSubmit(e)}
            buttonTitle='Sign out'
            colour='#17181d'
          />
        </Actions>
      </Layout>
    </Fragment>
  )
}

const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr;
  align-items: center;
`

const Avatar = styled.img``

const Info = styled.div`
  color: #fff;
  line-height: 1.4;

  span {
    color: rgba(152, 152, 152, 1);
  }
`

const FightStreaks = styled.div`
  display: grid;
  grid-template-columns: 8rem 1fr;
  align-items: center;
  column-gap: 4rem;
  margin-top: 2rem;
`

const TotalScorecards = styled.div`
  display: grid;
  grid-row-columns: 1rem 20px;
  text-align: center;

  p {
    font-size: 3.25rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin: 0;
  }

  span {
    color: rgba(152, 152, 152, 1);
    font-size: 1rem;
    margin: 0;
  }
`

const Streak = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-template: auto;
`

const HorizontalDivide = styled.div`
  padding-top: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #3c3c3c;
  display: grid;
  grid-template-columns: auto;
`

const HistoricalData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  row-gap: 1.5rem;
  color: #fff;

  h3 {
    margin-bottom: 0.5rem;
  }
`

const Actions = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 10rem 10rem;
  justify-content: start;
  column-gap: 1rem;
`

const ChartWrapper = styled.div`
  display: grid;
  height: 14rem;
  background: #141419;
  border: 1px solid #282828;
  border-radius: 0.5rem;
  padding: 0 2rem;
  grid-template-rows: 3rem 1fr;
  row-gap: 1rem;

  .highcharts-credits,
  .highcharts-legend {
    display: none;
  }
`

const ChartsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: space-between;
  align-items: center;
`

const Title = styled.h3`
  color: #fff;
  font-size: 1rem;
`

const Stats = styled.p`
  color: rgba(152, 152, 152, 1);
  text-align: right;
`

// Modal
const ModalContainer = styled.div`
  background: #2a2b2f;
  color: #fff;
  border: 1px solid rgba(152, 152, 152, 0.2);
  padding: 0rem 2rem;
  display: grid;
  grid-template-rows: 4rem 4rem 4rem;
  border-radius: 6px;
  box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.6);
`

const ModalTitle = styled.h2`
  font-size: 32px;
`

const ModalDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
`

const ModalActions = styled.div`
  display: grid;
  grid-template-columns: 10rem 10rem;
  justify-content: end;
  column-gap: 2rem;
`
