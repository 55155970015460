import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layouts/base_layout'
import RegularButton from 'components/buttons/regular_button'
import { Showcase } from 'components/showcase'
import { FightListItem } from 'components/fight_list_item/fight_list_item'
import { device } from '../../../utils/media-queries'

export function ListLayout(props: any) {
  const {
    title,
    showcaseTitle,
    listTitle,
    fights,
    showcase1,
    showcase1_boxerA_avatar,
    showcase1_boxerB_avatar,
    showcase2,
    showcase2_boxerA_avatar,
    showcase2_boxerB_avatar,
    current_user,
    csrf_param,
    csrf_token,
  } = props
  let adminAction

  if (current_user != null) {
    if (current_user.role == 'admin') {
      adminAction = (
        <>
          <RegularButton path={`/fights/new`} buttonTitle='New' />
        </>
      )
    }
  }

  const FightList = ({ fights }) => (
    <List>
      {fights.map((classic: any) => (
        <div key={classic.id}>
          <FightListItem
            boxerA={classic.boxerA}
            boxerB={classic.boxerB}
            date={classic.fightDate}
            rounds={classic.rounds}
            id={classic.id}
            series={classic.series}
            weightClass={classic.weightClass}
            postResult={classic.postResult}
            current_user={current_user}
            csrf_param={csrf_param}
            csrf_token={csrf_token}
          />
        </div>
      ))}
    </List>
  )

  return (
    <Layout
      title={title}
      fightSearch={true}
      current_user={current_user}
      showcaseTitle={showcaseTitle}
      listLitle={listTitle}
      showcase1={showcase1}
      showcase1_boxerA_avatar={showcase1_boxerA_avatar}
      showcase1_boxerB_avatar={showcase1_boxerB_avatar}
      showcase2={showcase2}
      showcase2_boxerA_avatar={showcase2_boxerA_avatar}
      showcase2_boxerB_avatar={showcase2_boxerB_avatar}
      csrf_param={csrf_param}
      csrf_token={csrf_token}>
      <Container>
        <div>
          <SectionTitle>{showcaseTitle}</SectionTitle>
          <Showcases>
            <Showcase
              showcase={showcase1}
              showcase_boxerA_avatar={showcase1_boxerA_avatar}
              showcase_boxerB_avatar={showcase1_boxerB_avatar}
              current_user={current_user}
              csrf_param={csrf_param}
              csrf_token={csrf_token}
            />

            <Showcase
              showcase={showcase2}
              showcase_boxerA_avatar={showcase2_boxerA_avatar}
              showcase_boxerB_avatar={showcase2_boxerB_avatar}
              current_user={current_user}
              csrf_param={csrf_param}
              csrf_token={csrf_token}
            />
          </Showcases>
        </div>
        <SectionTitle>{listTitle}</SectionTitle>
        <FightList fights={fights} />
        {adminAction}
      </Container>
    </Layout>
  )
}

const List = styled.ul`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3%;
  row-gap: 2rem;
`

const Showcases = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #3c3c3c;
  padding: 1rem 0;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-rows: 25rem 1fr;
  padding-bottom: 2rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 44rem 1fr;
  }
`

const SectionTitle = styled.h3`
  margin-top: 0;
  color: #fff;
  font-weight: 500;
`
