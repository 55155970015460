import React, { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../../utils/media-queries'

// @ts-ignore
import image from '../../../../assets/images/fs-logo-square.png'

export default function Sidebar(props: any) {
  const { current_user } = props
  var current_url = window.location.href
  let deliverMenuItems
  let profileLink
  const menuItems = [
    { title: 'Scorecards', link: '/scorecards/' },
    {
      title: 'Discover fights',
      link: '/fights/',
      children: [
        { title: 'Upcoming schedule', link: '/fights/scheduled/all/' },
        { title: 'Classic fights', link: '/fights/classics/all/' },
        { title: 'Boxers', link: '/boxers/' },
        { title: '+ New custom', link: '/scorecards/new/' },
      ],
    },
  ]

  const adminMenuItems = [
    { title: 'Scorecards', link: '/scorecards/' },
    {
      title: 'Discover fights',
      link: '/fights/',
      children: [
        { title: 'Upcoming schedule', link: '/fights/scheduled/all/' },
        { title: 'Classic fights', link: '/fights/classics/all/' },
        { title: 'Boxers', link: '/boxers/' },
        { title: '+ New custom', link: '/scorecards/new/' },
      ],
    },
    { title: 'Admin', link: '/admin/dashboard/' },
  ]

  if (current_user != null) {
    profileLink = (
      <ProfileLink href={`/users/${current_user.id}`}>
        {current_user.email}
        <span>{current_user.fight_count} scorecards</span>
      </ProfileLink>
    )
  } else {
    profileLink = (
      <ProfileLink href={`/users/sign_up`}>
        Sign up
        <span>0 scorecards</span>
      </ProfileLink>
    )
  }

  if (current_user != null) {
    if (current_user.role == 'admin') {
      deliverMenuItems = adminMenuItems
    } else {
      deliverMenuItems = menuItems
    }
  } else {
    deliverMenuItems = menuItems
  }

  return (
    <Fragment>
      <Wrapper>
        <LogoWrapper>
          <a href='/scorecards' title='Logo link'>
            <Logo src={image} alt='Fight Scores - iOS Boxing Scorecards' />
          </a>
        </LogoWrapper>
        <NavList>
          {deliverMenuItems.map((item, index) =>
            item.children ? (
              <Fragment key={index}>
                <NavItem key={index} href={item.link}>
                  {item.title}
                </NavItem>
                {item.children.map((item, index) =>
                  current_url.indexOf(item.link) > -1 ? (
                    <SelectedChildNavItem key={index + 10} href={item.link}>
                      {item.title}
                    </SelectedChildNavItem>
                  ) : (
                    <ChildNavItem key={index + 10} href={item.link}>
                      {item.title}
                    </ChildNavItem>
                  ),
                )}
              </Fragment>
            ) : current_url.indexOf(item.link) > -1 ? (
              <Fragment key={index}>
                <SelectedNavItem key={index} href={item.link}>
                  {item.title}
                </SelectedNavItem>
              </Fragment>
            ) : (
              <Fragment key={index}>
                <NavItem key={index} href={item.link}>
                  {item.title}
                </NavItem>
              </Fragment>
            ),
          )}
        </NavList>
        <div />
        {profileLink}
      </Wrapper>
    </Fragment>
  )
}

const Wrapper = styled.nav`
  background: #141419;
  position: sticky;
  top: 0;
  bottom: 0;
  padding-top: 2.75rem;
  height: 96vh;
  display: grid;
  grid-template-rows: 3.125rem 18.75rem auto 5.125rem;
  grid-template-columns: 100%;
  border-right: 1px solid #282828;

  @media ${device.mobileL} {
    display: none;
  }
`
const LogoWrapper = styled.section`
  display: grid;
  justify-content: center;
`

const Logo = styled.img`
  width: 3.5rem;
`

const NavList = styled.div`
  padding: 1rem;
  display: grid;
  justify-content: start;
  grid-template-columns: 100%;
  gap: 0rem;
`

const NavItem = styled.a`
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  color: white;

  &:hover {
    background: #1b1c21;
  }
`

const SelectedNavItem = styled.a`
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  color: #bca573;
  background: #1b1c21;
`

const ChildNavItem = styled.a`
  border-radius: 0.375rem;
  padding: 0.75rem 1rem 0.75rem 2rem;
  color: rgb(152, 152, 152);
  display: grid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #1b1c21;
  }
`

const SelectedChildNavItem = styled.a`
  border-radius: 0.375rem;
  padding: 0.75rem 1rem 0.75rem 2rem;
  color: #bca573;
  background: #1b1c21;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileLink = styled.a`
  margin: 0 1rem 1rem 1rem;
  border-radius: 0.375rem;
  padding: 0.75rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  line-height: 1.4;

  span {
    color: rgb(152, 152, 152);
    display: grid;
  }

  &:hover {
    background: #1b1c21;
  }
`
