import React, { Fragment } from 'react'
import FormattedDate from '../utils/date'

export function DateDisplay(props: any) {
  let { fight, date } = props
  let dateUI

  if (fight.series == 0 || fight.series == 1) {
    dateUI = <FormattedDate dateString={date} />
  } else if (fight.series == 2) {
    dateUI = (
      <>
        <FormattedDate dateString={date} /> - Rematch
      </>
    )
  } else if (fight.series == 3) {
    dateUI = (
      <Fragment>
        <FormattedDate dateString={date} /> - Trilogy
      </Fragment>
    )
  } else if (fight.series == 4) {
    dateUI = (
      <Fragment>
        <FormattedDate dateString={date} /> - Quadrilogy
      </Fragment>
    )
  }

  return <>{dateUI}</>
}
