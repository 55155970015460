import React from 'react'
import styled from 'styled-components'
import { DateDisplay } from 'components/date_display'
import { device } from '../utils/media-queries'
import RegularButton from 'components/buttons/regular_button'

export function Showcase(props: any) {
  const {
    fight,
    showcase,
    showcase_boxerA_avatar,
    showcase_boxerB_avatar,
    csrf_param,
    csrf_token,
    current_user,
  } = props
  let imageBoxerA
  let imageBoxerB

  function handleCreate(e: any) {
    const newFight = {
      ...fight,
      boxerA: showcase.boxerA,
      boxerB: showcase.boxerB,
      rounds: showcase.rounds,
      recommendation_id: showcase.id,
      nsDate: showcase.fightDate,
      series: showcase.series,
      user_id: current_user.id,
      weightClass: showcase.weightClass,
      boxerARnd1: 0,
      boxerARnd2: 0,
      boxerARnd3: 0,
      boxerARnd4: 0,
      boxerARnd5: 0,
      boxerARnd6: 0,
      boxerARnd7: 0,
      boxerARnd8: 0,
      boxerARnd9: 0,
      boxerARnd10: 0,
      boxerARnd11: 0,
      boxerARnd12: 0,
      boxerARnd13: 0,
      boxerARnd14: 0,
      boxerARnd15: 0,
      boxerATotal: 0,
      boxerBRnd1: 0,
      boxerBRnd2: 0,
      boxerBRnd3: 0,
      boxerBRnd4: 0,
      boxerBRnd5: 0,
      boxerBRnd6: 0,
      boxerBRnd7: 0,
      boxerBRnd8: 0,
      boxerBRnd9: 0,
      boxerBRnd10: 0,
      boxerBRnd11: 0,
      boxerBRnd12: 0,
      boxerBRnd13: 0,
      boxerBRnd14: 0,
      boxerBRnd15: 0,
      boxerBTotal: 0,
      postResultRnd: 0,
      fightContext: 'Future',
      postWinner: 'nil',
      postResult: 'nil',
      notes: 'No notes have been added.',
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: '/fights/',
      type: 'POST',
      data: { fight: newFight },
      success: function (data) {
        console.log(`Fight created - ${data}`)
      }.bind(this),
      error: function (response, status, err) {
        console.log(response, status, err)
      },
    })
  }

  if (showcase.boxerA_id != null) {
    imageBoxerA = (
      <BoxerImage src={showcase_boxerA_avatar} alt={showcase.boxerA} />
    )
  } else {
    imageBoxerA = (
      <BoxerImage
        src='https://s3.amazonaws.com/fightscoresapp.com/BoxerA.png'
        alt='no fighter image'
      />
    )
  }

  if (showcase.boxerB_id != null) {
    imageBoxerB = (
      <BoxerImage src={showcase_boxerB_avatar} alt={showcase.boxerB} />
    )
  } else {
    imageBoxerB = (
      <BoxerImage
        src='https://s3.amazonaws.com/fightscoresapp.com/BoxerB.png'
        alt='no fighter image'
      />
    )
  }

  return (
    <form
      className='new_recommendation'
      id='new_recommendation'
      action='new_recommendation'
      acceptCharset='UTF-8'
      method='post'>
      <input name='utf8' type='hidden' value='✓' />
      <input type='hidden' name={csrf_param} value={csrf_token} />
      <Container>
        <BoxerImages>
          {imageBoxerA}
          {imageBoxerB}
        </BoxerImages>
        <FightDateLabel>
          <DateDisplay fight={showcase} date={showcase.fightDate} />
        </FightDateLabel>
        <BoxerNames>
          {showcase.boxerA} vs {showcase.boxerB}
        </BoxerNames>
        <Actions>
          <RegularButton
            buttonTitle='Score this fight'
            onClick={(event) => handleCreate(event)}
          />
          <RegularButton
            buttonTitle='Fight info'
            path={'/fights/' + showcase.id}
            colour='#2a2b2f'
          />
        </Actions>
      </Container>
    </form>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 1.4rem 3rem 4rem;
`

const BoxerImages = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
`

const FightDateLabel = styled.p`
  text-align: center;
  color: #666;
`

const BoxerNames = styled.p`
  text-align: center;
  color: #bca573;
`

const Actions = styled.div`
  display: grid;
  grid-template-columns: 12rem 12rem;
  justify-content: center;
  column-gap: 0.5rem;

  @media ${device.mobileL} {
    grid-template-columns: 1fr 1fr;
  }
`

const BoxerImage = styled.img`
  height: 9.5rem;
  width: 9.5rem;
  background: #000;
`
