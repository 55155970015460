import React, { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../../utils/media-queries'

import ScorecardSummary from '../../scorecards/summary'
import Layout from 'components/layouts/base_layout'

export default function Scorecards(props: any) {
  let {
    scorecards,
    scorecard_count,
    per_page,
    total_pages,
    current_page,
    current_user,
    next_page,
    first_page,
    previous_page,
    last_page,
  } = props

  const ScorecardList = ({ scorecards }) => (
    <List>
      {scorecards.map((scorecard: any) => (
        <div key={scorecard.id}>
          <ScorecardSummary
            boxerA={scorecard.boxerA}
            boxerB={scorecard.boxerB}
            boxerATotal={scorecard.boxerATotal}
            boxerBTotal={scorecard.boxerBTotal}
            date={scorecard.nsDate}
            rounds={scorecard.rounds}
            id={scorecard.id}
            series={scorecard.series}
            postWinner={scorecard.postWinner}
          />
        </div>
      ))}
    </List>
  )

  let paginationUI
  if (scorecard_count > per_page) {
    if (current_page == first_page) {
      paginationUI = (
        <PaginationWrapper>
          <Pagination>
            <span>Previous</span>
            <span>
              Page {current_page} of {total_pages}
            </span>
            <a href={`${/scorecards/}?page=${next_page}`}>Next</a>
          </Pagination>
        </PaginationWrapper>
      )
    } else if (current_page == last_page) {
      paginationUI = (
        <PaginationWrapper>
          <Pagination>
            <a href={`${/scorecards/}?page=${previous_page}`}>Previous</a>
            <span>
              Page {current_page} of {total_pages}
            </span>
            <span>Next</span>
          </Pagination>
        </PaginationWrapper>
      )
    } else {
      paginationUI = (
        <PaginationWrapper>
          <Pagination>
            <a href={`${/scorecards/}?page=${previous_page}`}>Previous</a>
            <span>
              Page {current_page} of {total_pages}
            </span>
            <a href={`${/scorecards/}?page=${next_page}`}>Next</a>
          </Pagination>
        </PaginationWrapper>
      )
    }
  }

  return (
    <Fragment>
      <Layout title='Scorecards' boxerSearch={true} current_user={current_user}>
        <ScorecardList scorecards={scorecards} />
        {paginationUI}
      </Layout>
    </Fragment>
  )
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 3%;
  row-gap: 2rem;
  transition: all 0.5s ease-in-out;

  @media ${device.mobileL} {
    grid-template-columns: 100%;
    row-gap: 1rem;
  }
`

const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: end;
  padding: 2rem;
`

const Pagination = styled.div`
  display: grid;
  grid-template-columns: 1fr 7rem 1fr;
  column-gap: 0.5rem;
  width: 12rem;

  a {
    color: #fff;
  }
  span {
    color: #666;
    text-align: center;
  }
`
