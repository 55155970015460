import React from 'react'
import styled from 'styled-components'
import { device } from '../../utils/media-queries'

import { Router } from 'react-router-dom'
import history from 'components/utils/history'

// Custom imports
import Header from 'components/chrome/header'
import Sidebar from 'components/chrome/sidebar'
import MobileNav from 'components/chrome/sidebar/mobileNav'

export default function Layout(props: any) {
  const {
    title,
    backButton,
    boxerSearch,
    fightSearch,
    buttonTitle,
    path,
    current_user,
  } = props
  return (
    <Router history={history}>
      <BodyWrapper id='body-wrap'>
        <MobileNav
          current_user={current_user}
          pageWrapId={'container'}
          outerContainerId={'body-wrap'}
          className='mobile-nav'
        />
        <Container id='container'>
          <Sidebar current_user={current_user} />
          <MainContent>
            <Header
              title={title}
              backButton={backButton}
              boxerSearch={boxerSearch}
              fightSearch={fightSearch}
              buttonTitle={buttonTitle}
              path={path}
            />
            <div>{props.children}</div>
          </MainContent>
        </Container>
      </BodyWrapper>
    </Router>
  )
}

const BodyWrapper = styled.section`
  .bm-burger-button {
    display: none;
  }

  @media ${device.mobileL} {
    /* Position and sizing of burger button */
    .bm-burger-button {
      position: relative;
      width: 24px;
      height: 20px;
      left: 26px;
      top: 24px;
      display: block;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #bca573;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }

    /* General sidebar styles */
    .bm-menu {
      background: #141419;
      padding: 0;
    }

    .bm-menu-wrap {
      top: 0;
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`

const Container = styled.section`
  display: grid;
  gap: 4rem;
  grid-template-columns: 17.5rem auto;

  @media ${device.laptop} {
    grid-template-columns: 14.5rem auto;
  }
  @media ${device.tablet} {
    grid-template-columns: 12.5rem auto;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-auto-flow: column;
  }
`
const MainContent = styled.section`
  display: grid;
  grid-template-rows: 12rem 1fr;
  grid-auto-flow: row;
  width: 90%;

  @media ${device.mobileL} {
    width: 86%;
    margin: 0 auto;
    grid-template-rows: 8rem 1fr;
  }
`
