import React, { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../../../utils/media-queries'
import moment from 'moment'
import BaseLayout from 'components/layouts/base_layout'
import Scorecard from './scorecard'
import { DateDisplay } from 'components/date_display'

export default function Fight(props: any) {
  let { fight, scorecard, boxerA_avatar, boxerB_avatar, current_user } = props
  let result
  let buttonBoxerA
  let buttonBoxerB
  let imageBoxerA
  let imageBoxerB
  let editUI
  var todaysDate = moment(new Date()).format('YYYY-MM-DD')

  if (fight.fightDate < todaysDate) {
    if (fight.postWinner == 'nil') {
      result = <span>To be decided</span>
    } else if (fight.postWinner == 'Draw') {
      result = (
        <span>
          {fight.postWinner} - {fight.postResultRnd}
        </span>
      )
    } else {
      result = (
        <span>
          {fight.postWinner} - {fight.postResult} {fight.postResultRnd}{' '}
        </span>
      )
    }
  } else {
    result = <span>To be decided</span>
  }

  if (fight.boxerA_id != null) {
    buttonBoxerA = (
      <ActionButton href={`/boxers/${fight.boxerA_id}`}>
        View more {fight.boxerA} fights
      </ActionButton>
    )
  }

  if (fight.boxerB_id != null) {
    buttonBoxerB = (
      <ActionButton href={`/boxers/${fight.boxerB_id}`}>
        View more {fight.boxerB} fights
      </ActionButton>
    )
  }

  if (fight.boxerA_id != null) {
    imageBoxerA = <BoxerAImage src={boxerA_avatar} alt={fight.boxerA} />
  } else {
    imageBoxerA = (
      <BoxerAImage
        src='https://s3.amazonaws.com/fightscoresapp.com/BoxerA.png'
        alt='no fighter image'
      />
    )
  }

  if (fight.boxerB_id != null) {
    imageBoxerB = <BoxerBImage src={boxerB_avatar} alt={fight.boxerB} />
  } else {
    imageBoxerB = (
      <BoxerBImage
        src='https://s3.amazonaws.com/fightscoresapp.com/BoxerB.png'
        alt='no fighter image'
      />
    )
  }

  if (current_user != null) {
    if (current_user.role == 'admin') {
      editUI = <AdminLink href={`/fights/${fight.id}/edit`}>Edit</AdminLink>
    }
  }

  function handleCreate(e: any) {
    const newScorecard = {
      ...scorecard,
      boxerA: fight.boxerA,
      boxerB: fight.boxerB,
      rounds: fight.rounds,
      recommendation_id: fight.id,
      nsDate: fight.fightDate,
      series: fight.series,
      user_id: current_user.id,
      weightClass: fight.weightClass,
      boxerARnd1: 0,
      boxerARnd2: 0,
      boxerARnd3: 0,
      boxerARnd4: 0,
      boxerARnd5: 0,
      boxerARnd6: 0,
      boxerARnd7: 0,
      boxerARnd8: 0,
      boxerARnd9: 0,
      boxerARnd10: 0,
      boxerARnd11: 0,
      boxerARnd12: 0,
      boxerARnd13: 0,
      boxerARnd14: 0,
      boxerARnd15: 0,
      boxerATotal: 0,
      boxerBRnd1: 0,
      boxerBRnd2: 0,
      boxerBRnd3: 0,
      boxerBRnd4: 0,
      boxerBRnd5: 0,
      boxerBRnd6: 0,
      boxerBRnd7: 0,
      boxerBRnd8: 0,
      boxerBRnd9: 0,
      boxerBRnd10: 0,
      boxerBRnd11: 0,
      boxerBRnd12: 0,
      boxerBRnd13: 0,
      boxerBRnd14: 0,
      boxerBRnd15: 0,
      boxerBTotal: 0,
      postResultRnd: 0,
      fightContext: 'Future',
      postWinner: 'nil',
      postResult: 'nil',
      notes: 'No notes have been added.',
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: '/scorecards/',
      type: 'POST',
      data: { fight: newScorecard },
      success: function () {
        console.log('Fight created')
      }.bind(this),
      error: function (response, status, err) {
        console.log(response, status, err)
      },
    })
  }

  let scoreFightUI

  if (current_user != null) {
    scoreFightUI = (
      <ActionButton onClick={(event) => handleCreate(event)}>
        Score this fight
      </ActionButton>
    )
  } else {
    scoreFightUI = (
      <ActionButton href='/users/sign_up/'>Score this fight</ActionButton>
    )
  }

  return (
    <Fragment>
      <BaseLayout
        title='All fights'
        backButton={true}
        buttonTitle='Schedule'
        path='/fights/scheduled/all/'
        current_user={current_user}>
        <Container>
          <TopSection>
            <BoxerIntro>
              <FightDate>
                <DateDisplay fight={fight} date={fight.fightDate} /> {result}{' '}
                {editUI}
              </FightDate>
              <FightIntro>
                <BoxerImageWrapper>
                  {imageBoxerA}
                  {imageBoxerB}
                </BoxerImageWrapper>
                <BoxerTitle>
                  {fight.boxerA} vs <br />
                  {fight.boxerB}
                </BoxerTitle>
              </FightIntro>
            </BoxerIntro>
          </TopSection>
          <BottomSection>
            <UserCount>
              <span>{fight.fight_count}</span> people have scored this fight so
              far.
            </UserCount>
            <Scorecard recommendation={fight} />
            <ActionRow>
              {scoreFightUI}
              {buttonBoxerA}
              {buttonBoxerB}
            </ActionRow>
          </BottomSection>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem 1fr;
  height: 100vh;

  @media ${device.mobileL} {
    grid-template-rows: 20rem 1fr;
  }
`

const TopSection = styled.div`
  display: grid;
  width: 100%;
`
const FightDate = styled.p`
  color: #bca573;
  font-size: 0.875rem;
  margin-top: 0;
  span {
    padding-left: 0.5rem;
    color: #989898;
  }
`

const AdminLink = styled.a`
  color: #bca573;
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const BoxerImageWrapper = styled.div`
  @media ${device.mobileL} {
    display: block;
    margin: 0 auto;
  }
`

const BoxerAImage = styled.img`
  height: 8.25rem;
  width: 8.25rem;
  border: 1px solid rgba(152, 152, 152, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  transform: rotate(-19.26deg);
  background: #000;
  z-index: -1;
`

const BoxerBImage = styled.img`
  height: 8.25rem;
  width: 8.25rem;
  border: 1px solid rgba(152, 152, 152, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  z-index: 1;
`

const BoxerIntro = styled.div`
  display: grid;
  grid-template-row: 5rem 10rem;
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;

  @media ${device.mobileL} {
    padding-top: 2rem;
    grid-template-columns: auto;
    grid-template-rows: 10rem 1fr;
  }
`

const BoxerTitle = styled.h1`
  color: white;
  font-size: 34px;
  margin-bottom: 0.5rem;

  @media ${device.mobileL} {
    font-size: 24px;
    text-align: center;
  }
`

const BottomSection = styled.section`
  background: #17171c;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 8rem 3rem;
  align-items: top;
  margin: 0 -11% 0 -4rem;
  padding: 2rem 11% 0 4rem;
  border-top: 1px solid #3c3c3c;
  row-gap: 2rem;
`

const UserCount = styled.p`
  color: #fff;
  span {
    font-weight: 600;
  }
`
const ActionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 3.25rem 3.25rem 3.25rem;
    row-gap: 1rem;
  }
`

const ActionButton = styled.a`
  text-align: center;
  color: rgba(255, 2255, 255, 0.7);
  border: 1px solid #313131;
  border-radius: 8px;
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: #fff;
    background: #1b1c21;
  }
`
