import React, { Fragment } from 'react'
import styled from 'styled-components'
import ScorecardSummary from '../../scorecards/summary'
import Layout from 'components/layouts/base_layout'

export default function SearchResults(props: any) {
  let { searchable_user_fights, searchable_user_fights_count, current_user } =
    props
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const searchQuery = urlParams.get('query')

  const FightList = ({ searchable_user_fights }) => (
    <List>
      {searchable_user_fights.map((fight: any) => (
        <div key={fight.id}>
          <ScorecardSummary
            boxerA={fight.boxerA}
            boxerB={fight.boxerB}
            boxerATotal={fight.boxerATotal}
            boxerBTotal={fight.boxerBTotal}
            date={fight.nsDate}
            rounds={fight.rounds}
            id={fight.id}
            series={fight.series}
          />
        </div>
      ))}
    </List>
  )

  return (
    <Fragment>
      <Layout
        title={`Showing ${searchable_user_fights_count} results for '${searchQuery}'`}
        boxerSearch={true}
        current_user={current_user}>
        <FightList searchable_user_fights={searchable_user_fights} />
      </Layout>
    </Fragment>
  )
}

const List = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 3%;
  row-gap: 2rem;
`
