import React, { Fragment, useState } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import BaseLayout from 'components/layouts/base_layout'
import RegularButton from 'components/buttons/regular_button'
import { Switch } from 'components/buttons/switch'

import {
  FightForm,
  FightFormFieldLabel,
  FightFormFieldInput,
  FightFormFieldSelect,
  FightFormField,
  FightFormRowHalf,
  FightFormDateInput,
  FightFormFieldHalfWrapper,
} from 'components/forms'

export default function NewFight(props: any) {
  let { fight, csrf_param, csrf_token, current_user } = props
  let seriesUI
  let weightUI
  let switchCheck

  const [boxerAValue, setBoxerAValue] = useState('Boxer A')
  const [boxerBValue, setBoxerBValue] = useState('Boxer B')
  const [weightClassValue, setWeightClassValue] = useState('')
  const [roundsValue, setRoundsValue] = useState(12)
  var today = new Date()
  const [dateValue, setDateValue] = useState(today)
  const [seriesValue, setSeriesValue] = useState(0)
  const [isRecommendationActive, setIsRecommendationActiveValue] =
    useState(true)
  const [isRecommendationClassic, setIsRecommendationClassicValue] =
    useState(false)

  if (seriesValue == 0 || seriesValue == 1) {
    switchCheck = false
  } else {
    switchCheck = true
  }

  const [isRematchChecked, setRematchCheck] = useState(switchCheck)

  function handleCreate(e: any) {
    const newFight = {
      ...fight,
      boxerA: boxerAValue,
      boxerB: boxerBValue,
      rounds: roundsValue,
      fightDate: dateValue,
      series: seriesValue,
      isActive: isRecommendationActive,
      isClassic: isRecommendationClassic,
      weightClass: weightClassValue,
      boxerARnd1: 0,
      boxerARnd2: 0,
      boxerARnd3: 0,
      boxerARnd4: 0,
      boxerARnd5: 0,
      boxerARnd6: 0,
      boxerARnd7: 0,
      boxerARnd8: 0,
      boxerARnd9: 0,
      boxerARnd10: 0,
      boxerARnd11: 0,
      boxerARnd12: 0,
      boxerARnd13: 0,
      boxerARnd14: 0,
      boxerARnd15: 0,
      boxerATotal: 0,
      boxerBRnd1: 0,
      boxerBRnd2: 0,
      boxerBRnd3: 0,
      boxerBRnd4: 0,
      boxerBRnd5: 0,
      boxerBRnd6: 0,
      boxerBRnd7: 0,
      boxerBRnd8: 0,
      boxerBRnd9: 0,
      boxerBRnd10: 0,
      boxerBRnd11: 0,
      boxerBRnd12: 0,
      boxerBRnd13: 0,
      boxerBRnd14: 0,
      boxerBRnd15: 0,
      boxerBTotal: 0,
      postResultRnd: 0,
      postWinner: 'nil',
      postResult: 'nil',
      notes: 'No notes have been added.',
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: '/fights/',
      type: 'POST',
      data: { recommendation: newFight },
      success: function (data) {
        console.log(`Fight created - ${data}`)
      }.bind(this),
      error: function (response, status, err) {
        console.log(response, status, err)
      },
    })
  }

  weightUI = (
    <>
      <FightFormFieldLabel htmlFor='weight'>Weight Class</FightFormFieldLabel>
      <FightFormFieldSelect
        id='weight'
        name='weight'
        value={weightClassValue}
        onChange={(e) => setWeightClassValue(e.target.value)}>
        <option id='weight' value=''>
          None provided
        </option>
        <option id='weight' value='Heavy'>
          Heavyweight
        </option>
        <option id='weight' value='Cruiser'>
          Cruiserweight
        </option>
        <option id='weight' value='Light Heavy'>
          Light Heavyweight
        </option>
        <option id='weight' value='Super Middle'>
          Super Middleweight
        </option>
        <option id='weight' value='Middle'>
          Middleweight
        </option>
        <option id='weight' value='Super Welter'>
          Light Middleweight
        </option>
        <option id='weight' value='Welter'>
          Welterweight
        </option>
        <option id='weight' value='Super Light'>
          Super Lightweight
        </option>
        <option id='weight' value='Light'>
          Lightweight
        </option>
        <option id='weight' value='Super Feather'>
          Super Featherweight
        </option>
        <option id='weight' value='Feather'>
          Featherweight
        </option>
        <option id='weight' value='Super Bantam'>
          Super Bantamweight
        </option>
        <option id='weight' value='Bantam'>
          Bantamweight
        </option>
        <option id='weight' value='Super Fly'>
          Super Flyweight
        </option>
        <option id='weight' value='Fly'>
          Flyweight
        </option>
        <option id='weight' value='Light Fly'>
          Light Flyweight
        </option>
        <option id='weight' value='Minimum'>
          Minimumweight
        </option>
      </FightFormFieldSelect>
    </>
  )

  if (isRematchChecked) {
    seriesUI = (
      <FightFormFieldSelect
        id='series'
        name='series'
        value={seriesValue}
        onChange={(e) => setSeriesValue(e.target.value)}>
        <option id='series' value={1}>
          0
        </option>
        <option id='series' value={2}>
          2 - Rematch
        </option>
        <option id='series' value={3}>
          3 - Trilogy
        </option>
        <option id='series' value={4}>
          4 - Quadrilogy
        </option>
      </FightFormFieldSelect>
    )
  } else {
    seriesUI = ''
  }

  return (
    <Fragment>
      <BaseLayout
        title='New scheduled fight'
        backButton={true}
        buttonTitle='Schedule'
        path='/fights/'
        current_user={current_user}>
        <Container>
          <FightIntro>
            <FightTitle>
              <FightDate>New fight</FightDate>
              <Title>Add fight details</Title>
              <TitleUnderline />
            </FightTitle>
          </FightIntro>

          <FightDetails>
            <FightForm
              className='edit_recommendation'
              id={`edit_recommendation_${fight.id}`}
              action={`/fights/${fight.id}`}
              acceptCharset='UTF-8'
              method='post'>
              <input name='utf8' type='hidden' value='✓' />
              <input type='hidden' name='_method' value='patch' />
              <input type='hidden' name={csrf_param} value={csrf_token} />
              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerA'>
                    Boxer A name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerA'
                    id='boxerA'
                    type='text'
                    value={boxerAValue}
                    onChange={(e) => setBoxerAValue(e.target.value)}
                  />
                </FightFormField>

                <FightFormField>
                  <FightFormFieldLabel htmlFor='boxerB'>
                    Boxer B name
                  </FightFormFieldLabel>
                  <FightFormFieldInput
                    name='boxerB'
                    id='boxerB'
                    type='text'
                    value={boxerBValue}
                    onChange={(e) => setBoxerBValue(e.target.value)}
                  />
                </FightFormField>
              </FightFormRowHalf>

              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='rounds'>
                    Scheduled Rounds
                  </FightFormFieldLabel>
                  <FightFormFieldSelect
                    id='rounds'
                    name='rounds'
                    value={roundsValue}
                    onChange={(e) => setRoundsValue(e.target.value)}>
                    <option id='rounds' value={3}>
                      3
                    </option>
                    <option id='rounds' value={4}>
                      4
                    </option>
                    <option id='rounds' value={5}>
                      5
                    </option>
                    <option id='rounds' value={6}>
                      6
                    </option>
                    <option id='rounds' value={8}>
                      8
                    </option>
                    <option id='rounds' value={10}>
                      10
                    </option>
                    <option id='rounds' value={12}>
                      12
                    </option>
                    <option id='rounds' value={15}>
                      15
                    </option>
                  </FightFormFieldSelect>
                </FightFormField>
                <FightFormField>{weightUI}</FightFormField>
              </FightFormRowHalf>
              <FightFormRowHalf>
                <FightFormField>
                  <FightFormFieldLabel>Fight date</FightFormFieldLabel>
                  <FightFormDateInput>
                    <DatePicker
                      dateFormat='yyyy-MM-dd'
                      selected={dateValue}
                      onChange={(date) => setDateValue(date)}
                    />
                  </FightFormDateInput>
                </FightFormField>
                <FightFormField>
                  <FightFormFieldLabel htmlFor='recommendation_id'>
                    Rematch
                  </FightFormFieldLabel>

                  <RematchContainer>
                    <Switch
                      checked={isRematchChecked}
                      onChange={(e) => setRematchCheck(e.target.checked)}
                    />
                    {seriesUI}
                  </RematchContainer>
                </FightFormField>
              </FightFormRowHalf>
              <FightFormRowHalf>
                <FightFormFieldHalfWrapper>
                  <FightFormField>
                    <FightFormFieldLabel htmlFor='recommendation_isActive'>
                      Active
                    </FightFormFieldLabel>

                    <RematchContainer>
                      <Switch
                        checked={isRecommendationActive}
                        onChange={(e) =>
                          setIsRecommendationActiveValue(e.target.checked)
                        }
                      />
                    </RematchContainer>
                  </FightFormField>

                  <FightFormField>
                    <FightFormFieldLabel htmlFor='recommendation_isClassic'>
                      Classic
                    </FightFormFieldLabel>

                    <RematchContainer>
                      <Switch
                        checked={isRecommendationClassic}
                        onChange={(e) =>
                          setIsRecommendationClassicValue(e.target.checked)
                        }
                      />
                    </RematchContainer>
                  </FightFormField>
                </FightFormFieldHalfWrapper>
              </FightFormRowHalf>

              <Actions>
                <RegularButton
                  path={'/fights/' + fight.id}
                  buttonTitle='Cancel'
                />
                <RegularButton
                  buttonTitle='Create fight'
                  onClick={(event) => handleCreate(event)}
                  colour='#bca573'
                />
              </Actions>
            </FightForm>
          </FightDetails>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2rem;
  padding-top: 1rem;
`

const FightIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-contents: space-between;
  align-items: center;
`

const FightTitle = styled.div`
  display: grid;
  grid-template-rows: 1rem 1fr 0.5rem;
`

const FightDate = styled.div`
  color: #989898;
  font-size: 0.875rem;
`

const Title = styled.h1`
  color: white;
  font-size: 34px;
  margin: 0.75rem 0;
`

const TitleUnderline = styled.div`
  height: 2px;
  width: 8rem;
  background: #bca573;
`

const FightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 3rem 1fr;
  row-gap: 2rem;
`

const Actions = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 7rem 10rem 10rem;
  justify-content: end;
  column-gap: 1rem;
`

const RematchContainer = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
`
