import React, { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../utils/media-queries'

export function Round(props: any) {
  const { round, boxerARoundScore, boxerBRoundScore } = props

  const RoundNumber = styled.p`
    background: #1b1c21;
    margin: 0;
  `

  const BoxerAScore = styled.p`
    margin: 0;
    color: ${boxerARoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  const BoxerBScore = styled.p`
    margin: 0;
    color: ${boxerBRoundScore == 10 ? '#BCA573' : '#FFF'};
  `

  return (
    <RoundWrapper>
      <RoundNumber>{round}</RoundNumber>
      <BoxerAScore>{boxerARoundScore}</BoxerAScore>
      <BoxerBScore>{boxerBRoundScore}</BoxerBScore>
    </RoundWrapper>
  )
}

const RoundWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  min-width: 3.5rem;
`

export default function ScorecardTable(props: any) {
  let { scorecard } = props
  let roundsUI

  if (scorecard.rounds == 3) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 4) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 5) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={scorecard.boxerARnd5}
          boxerBRoundScore={scorecard.boxerBRnd5}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 6) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={scorecard.boxerARnd5}
          boxerBRoundScore={scorecard.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={scorecard.boxerARnd6}
          boxerBRoundScore={scorecard.boxerBRnd6}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 8) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={scorecard.boxerARnd5}
          boxerBRoundScore={scorecard.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={scorecard.boxerARnd6}
          boxerBRoundScore={scorecard.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={scorecard.boxerARnd7}
          boxerBRoundScore={scorecard.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={scorecard.boxerARnd8}
          boxerBRoundScore={scorecard.boxerBRnd8}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 10) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={scorecard.boxerARnd5}
          boxerBRoundScore={scorecard.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={scorecard.boxerARnd6}
          boxerBRoundScore={scorecard.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={scorecard.boxerARnd7}
          boxerBRoundScore={scorecard.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={scorecard.boxerARnd8}
          boxerBRoundScore={scorecard.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={scorecard.boxerARnd9}
          boxerBRoundScore={scorecard.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={scorecard.boxerARnd10}
          boxerBRoundScore={scorecard.boxerBRnd10}
        />
      </Fragment>
    )
  } else if (scorecard.rounds == 12) {
    roundsUI = (
      <Fragment>
        <Round
          round={1}
          boxerARoundScore={scorecard.boxerARnd1}
          boxerBRoundScore={scorecard.boxerBRnd1}
        />
        <Round
          round={2}
          boxerARoundScore={scorecard.boxerARnd2}
          boxerBRoundScore={scorecard.boxerBRnd2}
        />
        <Round
          round={3}
          boxerARoundScore={scorecard.boxerARnd3}
          boxerBRoundScore={scorecard.boxerBRnd3}
        />
        <Round
          round={4}
          boxerARoundScore={scorecard.boxerARnd4}
          boxerBRoundScore={scorecard.boxerBRnd4}
        />
        <Round
          round={5}
          boxerARoundScore={scorecard.boxerARnd5}
          boxerBRoundScore={scorecard.boxerBRnd5}
        />
        <Round
          round={6}
          boxerARoundScore={scorecard.boxerARnd6}
          boxerBRoundScore={scorecard.boxerBRnd6}
        />
        <Round
          round={7}
          boxerARoundScore={scorecard.boxerARnd7}
          boxerBRoundScore={scorecard.boxerBRnd7}
        />
        <Round
          round={8}
          boxerARoundScore={scorecard.boxerARnd8}
          boxerBRoundScore={scorecard.boxerBRnd8}
        />
        <Round
          round={9}
          boxerARoundScore={scorecard.boxerARnd9}
          boxerBRoundScore={scorecard.boxerBRnd9}
        />
        <Round
          round={10}
          boxerARoundScore={scorecard.boxerARnd10}
          boxerBRoundScore={scorecard.boxerBRnd10}
        />
        <Round
          round={11}
          boxerARoundScore={scorecard.boxerARnd11}
          boxerBRoundScore={scorecard.boxerBRnd11}
        />
        <Round
          round={12}
          boxerARoundScore={scorecard.boxerARnd12}
          boxerBRoundScore={scorecard.boxerBRnd12}
        />
      </Fragment>
    )
  }

  return (
    <Container>
      <RoundWrapper>
        <TopRow />
        <BoxerName>{scorecard.boxerA}</BoxerName>
        <BoxerName>{scorecard.boxerB}</BoxerName>
      </RoundWrapper>
      {roundsUI}
      <RoundWrapper>
        <TopRowTotal>TOTAL</TopRowTotal>
        <TotalScore>{scorecard.boxerATotal}</TotalScore>
        <TotalScore>{scorecard.boxerBTotal}</TotalScore>
      </RoundWrapper>
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 10rem auto;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid #313131;
  border-radius: 8px;
  line-height: 3;
  text-align: center;
  background: #141419;
  transition: 0.3s border-color ease-in-out;
  @media ${device.mobileL} {
    width: 40rem;
  }
`

const TopRow = styled.p`
  background: #1b1c21;
  margin: 0;

  &:first-of-type {
    border-top-left-radius: 8px;
  }

  &:last-of-type {
    border-top-right-radius: 8px;
  }
`

const TopRowTotal = styled.p`
  background: #1b1c21;
  margin: 0;
  padding-right: 0.25rem;

  &:first-of-type {
    border-top-right-radius: 8px;
  }
`

const BoxerName = styled.p`
  margin: 0;
  text-align: left;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TotalScore = styled.p`
  margin: 0;
`
