import React, { Fragment } from 'react'
import styled from 'styled-components'
import RegularButton from 'components/buttons/regular_button'
import { device } from '../utils/media-queries'
import { DateDisplay } from 'components/date_display'

export function FightListItem(props: any) {
  let {
    scorecard,
    id,
    date,
    series,
    boxerA,
    boxerB,
    rounds,
    weightClass,
    postResult,
    csrf_param,
    csrf_token,
    current_user,
  } = props
  let noWinnerUI

  if (current_user != null) {
    if (current_user.role == 'admin' && postResult == null) {
      noWinnerUI = <StarIcon>★</StarIcon>
    }
  }

  function handleCreate(e: any) {
    const newScorecard = {
      ...scorecard,
      boxerA: boxerA,
      boxerB: boxerB,
      rounds: rounds,
      recommendation_id: id,
      nsDate: date,
      series: series,
      user_id: current_user.id,
      weightClass: weightClass,
      boxerARnd1: 0,
      boxerARnd2: 0,
      boxerARnd3: 0,
      boxerARnd4: 0,
      boxerARnd5: 0,
      boxerARnd6: 0,
      boxerARnd7: 0,
      boxerARnd8: 0,
      boxerARnd9: 0,
      boxerARnd10: 0,
      boxerARnd11: 0,
      boxerARnd12: 0,
      boxerARnd13: 0,
      boxerARnd14: 0,
      boxerARnd15: 0,
      boxerATotal: 0,
      boxerBRnd1: 0,
      boxerBRnd2: 0,
      boxerBRnd3: 0,
      boxerBRnd4: 0,
      boxerBRnd5: 0,
      boxerBRnd6: 0,
      boxerBRnd7: 0,
      boxerBRnd8: 0,
      boxerBRnd9: 0,
      boxerBRnd10: 0,
      boxerBRnd11: 0,
      boxerBRnd12: 0,
      boxerBRnd13: 0,
      boxerBRnd14: 0,
      boxerBRnd15: 0,
      boxerBTotal: 0,
      postResultRnd: 0,
      fightContext: 'Future',
      postWinner: 'nil',
      postResult: 'nil',
      notes: 'No notes have been added.',
    }
    e.preventDefault()
    // eslint-disable-next-line no-undef
    $.ajax({
      url: '/scorecards/',
      type: 'POST',
      data: { fight: newScorecard },
      success: function (data) {
        console.log(`Scorecard created - ${data}`)
      }.bind(this),
      error: function (response, status, err) {
        console.log(response, status, err)
      },
    })
  }

  return (
    <Fragment>
      <form
        className='new_recommendation'
        id='new_recommendation'
        action='new_recommendation'
        acceptCharset='UTF-8'
        method='post'>
        <input name='utf8' type='hidden' value='✓' />
        <input type='hidden' name={csrf_param} value={csrf_token} />
        <Container>
          <DetailsWrap>
            <BoxerName href={`/fights/${id}/`}>
              {boxerA} vs {boxerB} {noWinnerUI}
            </BoxerName>
            <FightDate>
              <DateDisplay fight={props} date={date} />
            </FightDate>
          </DetailsWrap>
          <div>
            <RegularButton
              buttonTitle='Score this fight'
              onClick={(event) => handleCreate(event)}
            />
          </div>
        </Container>
      </form>
    </Fragment>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 10rem;
  align-items: center;
  justify-contents: space-between;
  row-gap: 1rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`
const DetailsWrap = styled.div`
  display: grid;
  grid-template-rows: 1.5rem 1rem;
  grid-template-columns: 1fr;
`
const FightDate = styled.p`
  color: rgb(152, 152, 152);
  margin: 0;
`

const BoxerName = styled.a`
  color: #fff;
  font-size: 1rem;
  width: 94%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StarIcon = styled.span`
  font-size: 0.875rem;
  padding-left: 0.5rem;
`
