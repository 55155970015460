import React from 'react'
import styled from 'styled-components'

export default function UserDecade(props: any) {
  let { decade, decadeFights, totalFights } = props
  let pecentage = ((decadeFights / totalFights) * 100).toFixed(0)

  return (
    <Decade>
      <DecadeYear>{decade}</DecadeYear>
      <DecadeStats>{`${decadeFights} scorecards (${pecentage}%)`}</DecadeStats>
    </Decade>
  )
}

const Decade = styled.div`
  display: grid;
  grid-row-columns: 1fr 1fr;
`

const DecadeYear = styled.h4`
  margin: 0.25rem 0;
`

const DecadeStats = styled.p`
  color: rgba(152, 152, 152, 1);
  font-size: 0.875rem;
  margin: 0;
`
