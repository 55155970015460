import React from 'react'
import styled from 'styled-components'
import { DateDisplay } from 'components/date_display'
import { device } from '../utils/media-queries'

export function PopularFights(props: any) {
  const { id, date, boxerA, boxerB, fight_count } = props

  return (
    <PopularFight href={'/fights/' + id}>
      <Count>{fight_count}</Count>
      <DetailsWrap>
        <Names>
          {boxerA} vs {boxerB}
        </Names>
        <Info>
          <DateDisplay fight={props} date={date} />
        </Info>
      </DetailsWrap>
    </PopularFight>
  )
}

const PopularFight = styled.a`
  display: grid;
  grid-template-columns: 5rem 1fr;
  align-items: center;
`

const Count = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  padding-top: 1.5rem;

  @media ${device.mobileL} {
  }
`
const DetailsWrap = styled.div`
  display: grid;
  grid-template-rows: 1.5rem 1rem;
`

const Names = styled.p`
  color: #fff;
  width: 94%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1rem;
`

const Info = styled.p`
  color: rgb(152, 152, 152);
`
