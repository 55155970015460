import React from 'react'
import styled from 'styled-components'

export default function UserStreak(props: any) {
  let { image, text } = props

  return (
    <Streak>
      <Icon src={image} alt='achievement icon' />
      <Text>{text}</Text>
    </Streak>
  )
}

const Streak = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  row-gap: 1rem;
`

const Icon = styled.img``

const Text = styled.p`
  color: #fff;
  padding: 0;
  margin: 0.35rem 0;
`
