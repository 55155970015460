import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layouts/base_layout'
import { device } from '../../utils/media-queries'
import { PopularFights } from 'components/popular_fights'
import RegularButton from 'components/buttons/regular_button'
import { FightListItem } from 'components/fight_list_item'
import { Showcase } from 'components/showcase'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

export default function DiscoverPage(props: any) {
  const {
    fights_2023,
    fights_2024,
    fights_all_time,
    showcase1,
    showcase1_boxerA_avatar,
    showcase1_boxerB_avatar,
    showcase2,
    showcase2_boxerA_avatar,
    showcase2_boxerB_avatar,
    scheduled,
    current_user,
    csrf_param,
    csrf_token,
  } = props

  const PopularFightList = ({ fights }) => (
    <List>
      {fights.map((fights: any) => (
        <li key={fights.id}>
          <PopularFights
            boxerA={fights.boxerA}
            boxerB={fights.boxerB}
            date={fights.fightDate}
            id={fights.id}
            fight_count={fights.fight_count}
            weightClass={fights.weightClass}
            series={fights.series}
            current_user={current_user}
          />
        </li>
      ))}
    </List>
  )

  const PopularTabs = () => (
    <Tabs>
      <TabList>
        <Tab>2024</Tab>
        <Tab>2023</Tab>
        <Tab>All time</Tab>
      </TabList>

      <TabPanel>
        <PopularFightList fights={fights_2024} />
      </TabPanel>
      <TabPanel>
        <PopularFightList fights={fights_2023} />
      </TabPanel>
      <TabPanel>
        <PopularFightList fights={fights_all_time} />
      </TabPanel>
    </Tabs>
  )

  const ScheduleList = ({ scheduled }) => (
    <PaddedList>
      {scheduled.map((fight: any) => (
        <li key={fight.id}>
          <FightListItem
            boxerA={fight.boxerA}
            boxerB={fight.boxerB}
            date={fight.fightDate}
            rounds={fight.rounds}
            id={fight.id}
            series={fight.series}
            weightClass={fight.weightClass}
            postResult={fight.postResult}
            current_user={current_user}
            csrf_param={csrf_param}
            csrf_token={csrf_token}
          />
        </li>
      ))}
    </PaddedList>
  )

  return (
    <Layout title='Discover' fightSearch={true} current_user={current_user}>
      <Container>
        <div>
          <SectionTitle>Major upcoming fights</SectionTitle>
          <Showcases>
            <Showcase
              showcase={showcase1}
              showcase_boxerA_avatar={showcase1_boxerA_avatar}
              showcase_boxerB_avatar={showcase1_boxerB_avatar}
              current_user={current_user}
              csrf_param={csrf_param}
              csrf_token={csrf_token}
            />

            <Showcase
              showcase={showcase2}
              showcase_boxerA_avatar={showcase2_boxerA_avatar}
              showcase_boxerB_avatar={showcase2_boxerB_avatar}
              current_user={current_user}
              csrf_param={csrf_param}
              csrf_token={csrf_token}
            />
          </Showcases>
        </div>

        <LowerContainer>
          <ContentArea>
            <SectionTitle>Popular past fights</SectionTitle>
            <PopularTabs />
          </ContentArea>
          <ContentArea>
            <SectionTitle>Upcoming schedule</SectionTitle>
            <ScheduleList scheduled={scheduled} />
            <RegularButton
              path='/fights/scheduled/all/'
              buttonTitle='View full schedule'
            />
          </ContentArea>
        </LowerContainer>
      </Container>
    </Layout>
  )
}

const LowerContainer = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;

  @media ${device.mobileL} {
    grid-template-columns: 1fr;
  }
`

const ContentArea = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr;

  .react-tabs {
    padding-top: 1rem;

    .react-tabs__tab-list {
      border-bottom: none;
      font-weight: 600;
      display: grid;
      list-style: none;
      grid-template-columns: 5rem 5rem 6rem;
      justify-items: start;
      padding-left: 0;
    }

    .react-tabs__tab {
      color: #fff;
      margin-right: 0.875rem;
      padding: 0.25rem 0.5rem;
      text-align: center;
    }

    .react-tabs__tab--selected {
      background: #bca573;
      border-radius: 2rem;
      color: #000;
      border: none;
    }
  }
`

const List = styled.ul`
  padding: 0;
  display: grid;
  list-style: none;
  grid-template-columns: 1fr;

  @media ${device.mobileL} {
    width: 94%;
    li {
      min-height: 4.25rem;
    }
  }
`

const PaddedList = styled.ul`
  padding: 2rem 0 0 0;
  display: grid;
  list-style: none;
  grid-template-columns: 1fr;

  @media ${device.mobileL} {
    padding: 0;
    width: 94%;

    li {
      min-height: 8.25rem;
      text-align: center;
    }
  }
`

const Showcases = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #3c3c3c;
  padding: 1rem 0;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-rows: 25rem 1fr;
  padding-bottom: 2rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 44rem 1fr;
  }
`

const SectionTitle = styled.h3`
  margin-top: 0;
  color: #fff;
  font-weight: 500;
`
