import styled from 'styled-components'
import { device } from '../utils/media-queries'

export const FightForm = styled.form`
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  max-width: 85%;
`

export const FightFormWide = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  max-width: 100%;
`

export const FightFormRowHalf = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 4rem;

  @media ${device.mobileL} {
    grid-template-columns: auto;
  }
`

export const FightFormField = styled.div`
  display: grid;
  grid-template-rows: 1rem 2.875rem;
  row-gap: 1rem;
`

export const FightFormFieldHalfWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
`

export const FightFormFieldLabel = styled.label`
  color: #989898;
  font-size: 14px;

  &:focus {
    color: #fff;
  }
`

export const FightFormFieldInput = styled.input`
  border: 1px solid rgba(152, 152, 152, 0.2);
  background: #1b1c21;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 100%;
  padding-left: 1rem;

  &:focus {
    outline: none;
    background: #2a2b2f;
  }
`

export const FightFormFieldTextArea = styled.textarea`
  border: 1px solid rgba(152, 152, 152, 0.2);
  background: #1b1c21;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 100%;
  padding: 1rem;

  &:focus {
    outline: none;
    background: #2a2b2f;
  }
`

export const FightFormFieldSelect = styled.select`
  -webkit-appearance: none;
  border: 1px solid rgba(152, 152, 152, 0.2);
  background: #1b1c21;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  height: 100%;
  padding-left: 1rem;
  min-height: 3rem;

  &:focus {
    outline: none;
    background: #2a2b2f;
  }
`

export const FightFormDateInput = styled.div`
  height: 100%;

  .react-datepicker-wrapper {
    height: 100%;
    width: 95%;

    .react-datepicker__input-container {
      height: 100%;
    }

    input {
      border: 1px solid rgba(152, 152, 152, 0.2);
      background: #1b1c21;
      border-radius: 8px;
      color: #fff;
      font-size: 14px;
      padding-left: 1rem;
      height: 100%;
      width: 100%;

      &:focus {
        outline: none;
        background: #2a2b2f;
      }
    }
  }

  .react-datepicker {
    background: #2a2b2f;
    color: #fff;
    border: 1px solid rgba(152, 152, 152, 0.2);

    .react-datepicker__day {
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        background: rgba(255, 255, 255, 0.4);
        color: #2a2b2f;
      }
    }

    .react-datepicker__day--selected {
      background: #bca573;
      color: #fff;
    }

    .react-datepicker__header {
      background: #2a2b2f;
      color: #fff;
    }

    .react-datepicker__current-month {
      color: #fff;
    }

    .react-datepicker__day-name {
      color: #fff;
    }

    .react-datepicker__triangle::after {
      border-top-color: #2a2b2f;
      border-bottom-color: #2a2b2f;
    }
    .react-datepicker__triangle::before {
      border-top-color: rgba(152, 152, 152, 0.6);
      border-bottom-color: rgba(152, 152, 152, 0.6);
    }
  }
`
