import React, { Fragment } from 'react'
import styled from 'styled-components'
import { device } from '../../../utils/media-queries'

import BaseLayout from 'components/layouts/base_layout'
import PastFight from 'components/past_fights'

export default function Boxer(props: any) {
  let {
    boxer,
    boxer_age,
    boxer_avatar,
    boxer_bouts,
    boxer_name,
    current_user,
  } = props
  let boxerImage
  let drawsUI
  if (boxer_avatar !== null) {
    boxerImage = <img src={boxer_avatar} alt={boxer_name} />
  } else {
    boxerImage = (
      <img
        src='https://s3.amazonaws.com/fightscoresapp.com/BoxerB.png'
        alt='no fighter image'
      />
    )
  }

  if (boxer.draws != 0) {
    drawsUI = (
      <>
        <span>D</span>
        {boxer.draws}
      </>
    )
  }

  return (
    <Fragment>
      <BaseLayout
        title='Boxers'
        backButton={true}
        buttonTitle='All Boxers'
        path='/boxers/'
        current_user={current_user}>
        <Container>
          <TopSection>
            <BoxerImageWrapper>{boxerImage}</BoxerImageWrapper>
            <BoxerIntro>
              <BoxerTitle>{boxer_name}</BoxerTitle>
              <BoxerRecord>
                <span>W</span>
                {boxer.wins}
                <span>KO</span>
                {boxer.ko}
                <span>L</span>
                {boxer.loses}
                {drawsUI}
              </BoxerRecord>
            </BoxerIntro>
          </TopSection>
          <BottomSection>
            <BoxerBio>
              <RowHalf>
                <div>
                  <label>Stance</label>
                  {boxer.stance}
                </div>

                <div>
                  <label>Age</label>
                  {boxer_age}
                </div>
              </RowHalf>
              <RowFull>
                <div>
                  <label>Hometown</label>
                  {boxer.birthplace}
                </div>
              </RowFull>
              <RowFull>
                <div>
                  <label>Nationality</label>
                  {boxer.nationality}
                </div>
              </RowFull>
            </BoxerBio>
            <BoxerPastFights>
              <label>Past fights</label>
              <FightList>
                {boxer_bouts.map((fight, index) => (
                  <PastFight
                    key={index}
                    fight={fight}
                    boxer_name={boxer_name}
                  />
                ))}
              </FightList>
            </BoxerPastFights>
          </BottomSection>
        </Container>
      </BaseLayout>
    </Fragment>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 8.5rem 1fr;
  min-height: 77vh;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 17.5rem 1fr;
  }
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 15rem 1fr;
  justify-contents: space-between;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
  }
`

const BoxerImageWrapper = styled.div`
  height: 11rem;
  width: 11rem;
  box-sizing: border-box;
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  background: black;
  z-index: 1;

  img {
    height: 11rem;
    width: 11rem;
  }

  @media ${device.mobileL} {
    height: 8.5rem;
    width: 8.5rem;
    display: block;
    margin: 0 auto;

    img {
      height: 8.5rem;
      width: 8.5rem;
    }
  }
`

const BoxerIntro = styled.div``

const BoxerTitle = styled.h1`
  color: white;
  font-size: 34px;
  margin-bottom: 0.5rem;

  @media ${device.mobileL} {
    font-size: 28px;
    text-align: center;
    margin-top: 0.5rem;
  }
`

const BoxerRecord = styled.h2`
  color: white;
  font-size: 28px;
  margin-top: 0;
  span {
    color: rgba(188, 165, 115, 1);
    margin-left: 1rem;
    margin-right: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  @media ${device.mobileL} {
    font-size: 20px;
    text-align: center;
  }
`

const BottomSection = styled.section`
  background: #17171c;
  display: grid;
  grid-template-columns: 15rem 1fr;
  justify-contents: space-between;
  margin: 0rem -11% 0 -4rem;
  padding: 2.5rem 11% 4rem 4rem;
  border-top: 1px solid #3c3c3c;
  height: 100%;

  @media ${device.mobileL} {
    grid-template-columns: auto;
    margin: -2.5rem -1.75rem 0 -1.75rem;
    padding: 0rem 1.75rem 0;
  }
`

const BoxerBio = styled.div`
  @media ${device.mobileL} {
  }
`

const BoxerPastFights = styled.div`
  padding-top: 2rem;

  label {
    color: #989898;
    display: grid;
    padding-bottom: 1rem;
  }

  @media ${device.mobileL} {
    padding-top: 0;
    padding-bottom: 2rem;
  }
`

// Form

const RowFull = styled.div`
  padding: 2rem 0 0;
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 500;
  color: #fff;

  label {
    color: #989898;
    display: grid;
    padding-bottom: 0.5rem;
  }

  @media ${device.mobileL} {
    width: 50%;
    float: left;
    padding-bottom: 2rem;
  }
`

const RowHalf = styled.div`
  padding: 2rem 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  label {
    color: #989898;
    display: grid;
    padding-bottom: 0.5rem;
  }
`

const FightList = styled.div`
  display: grid;
  row-gap: 1rem;
`
